
const curriculumdata = {
    "curriculum": [
        {
            "id": 1,
            "class1": "first",
            "class2": "box4",
            "title": "Mathematical concepts",
            "content": "Mathematical concepts are made understood through activities that include matching objects, labeling numerals, recognizing numbers, using comparative terms, sorting and classifying by attributes and many more.",
            "imgPath": "/assets/media/mediamath.jpg",
            "order": 1
        },
        {
            "id": 2,
            "class1": "",
            "class2": "box2",
            "title": "Language Concepts",
            "content": "Language concepts are developed through activities that include listening, dramatic play, recalling story events following verbal directions, utilizing language to form questions, identifying letter-sound relationships,matching & labeling letters, recognizing and writing letters or words phonetically.",
            "imgPath": "/assets/media/medialanguage.jpg",
            "order": 2
        },
        {
            "id": 3,
            "class1": "first",
            "class2": "box3",
            "title": "Play-based learning activities",
            "content": "In these self-doing activities kids love to participate and compete amongst themselves.Engaging activities are organized with daily life objects like clay, sand, food etc.to develop their vital skills.",
            "imgPath": "/assets/media/mediaplayway.jpg",
            "order": 3
        },
        {
            "id": 4,
            "class1": "",
            "class2": "box4",
            "title": "Art and Craft",
            "content": "We have designed our interdisciplinary curriculum with various art & craft activities which help them understand nature of art.Here kids perform different art techniques such as drawing, painting, cutting and pasting, tearing and rolling papers, rubbing and mixing colors and lot many techniques.",
            "imgPath": "/assets/media/mediaart.jpg",
            "order": 4
        },
        {
            "id": 5,
            "class1": "first",
            "class2": "box1",
            "title": "Creative activities",
            "content": "We foster kids’ brain and try to make them outspoken by encouraging them to participate in many activities to showcase their talent by using their creativity.It is fun observing kids performing in stage activities, action songs and picture stories.",
            "imgPath": "/assets/media/mediacreative%20activities.jpg",
            "order": 5
        },
        {
            "id": 6,
            "class1": "",
            "class2": "box2",
            "title": "Physical development activitie",
            "content": "At Rang Angels we have arranged Physical Development activities in our planner for kids that makes them super active and ensure their physical development.",
            "imgPath": "/assets/media/mediayoga.png",
            "order": 6
        },
        {
            "id": 7,
            "class1": "first",
            "class2": "box3",
            "title": "Fine motor skills",
            "content": "We follow scientifically designed activities to do develop Fine Motor Skills to write and manipulate small objects.These are developed through creative activities like playing with dough, sorting, dressing, coloring and doing crafts.",
            "imgPath": "/assets/media/mediafine.jpg",
            "order": 7
        },
        {
            "id": 8,
            "class1": "",
            "class2": "box1",
            "title": "Sensorial activities",
            "content": "We work on Sensorial Activities which stimulates child’s senseslike smell,taste,movement,balance,sight and hearing.We have designed integrated course to develop and build up sensorial skills.",
            "imgPath": "/assets/media/mediasens.jpg",
            "order": 8
        },
        {
            "id": 9,
            "class1": "first",
            "class2": "box1",
            "title": "Life skill",
            "content": "Life Skills is a diverse area, where children are taught the importance of using positive manner in both words & attitude, learning to follow directions, learning to solve problems verbally instead of using physical means and more.",
            "imgPath": "/assets/media/medialife.jpg",
            "order": 9
        },
        {
            "id": 10,
            "class1": "",
            "class2": "box2",
            "title": "Music skill",
            "content": "Music Skills help children to acquire language, sense of rhythm and develop Gross Motor Skill with their body movements.It includes copying movements, moving physically to music, dancing and using creative movements, expressing mood through movements and also child is acknowledged to identify instruments by its name.",
            "imgPath": "/assets/media/mediamusic.jpg",
            "order": 10
        },
        {
            "id": 11,
            "class1": "first",
            "class2": "box3",
            "title": "Science and nature studies",
            "content": "The children are provided the opportunity to make their own investigations and carry out innovative experiments.This leads them to explore the new world of tomorrow.Science concepts are made understood through activities that include observing and identifying body parts, using their five senses, colors, classifying and categorizing animals, identifying and learning about natural resources.",
            "imgPath": "/assets/media/medianature.jpg",
            "order": 11
        }
    ]
}
export default curriculumdata
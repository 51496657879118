const faqData = {
    "faqs": [
        {
            "id": 1,
            "title": "Why  should I choose Rang Angels ?",
            "content": "At RANG ANGELS, love care are the basic part .We aim to blossom a child’s 360 degree development. Our activity calendar is specially Design to bring out hidden talent of each and every child .It may be confidence, stage performance ,dance,drama, modeling ,Art-craft ,Speech ,Cooking   which are the part of",
            "order": 1
        },
        {
            "id": 2,
            "title": "What is the significance of early childhood education?  Why should I not send my Child directly to formal school?",
            "content": "Its Universal truth that up to 50% of a child’s ability to grasp new things develops by the age of 5 .And   A PLAY SCHOOL PLAYS  a vital role in later academic success .Here at  RANG ANGELS we have better language Skills ,Cognitive and social skills,maths and science skills which are very important for their preschool to life time .",
            "order": 2
        },
        {
            "id": 3,
            "title": "What is the right age to send a child to Pre School?",
            "content": "We can say 1.5 to 5.5 is the miracle stage .It is right time to develop their logical, Physical, Communication skill This is the right stage when they learn to speak few words and short sentences and happily stay apart from you for longer periods, they can develop the ability to learn trust, confidence and a positive self esteem.",
            "order": 3
        },
        {
            "id": 4,
            "title": "What is the curriculum and analysis way OF RANG ANGELS?",
            "content": "Our curriculum is quality Education system designed by long research .RANG ANGELS has its own analysis pattern which is based on child’s individual learning skills .We believe that  Motivation is the best key to perform well in all areas .we have set so many task and goals which are based on different skills .<p>To encourage each child we have started an initiative 'STAR STUDENT OF THE MONTH'",
            "order": 4
        },
        {
            "id": 5,
            "title": "Does my child get toilet training At RANG ANGELS ?",
            "content": "Yes RA Imparts very hygienic toilet training by following routine where in the child is taken to the wash room when he reaches before and after meal time and when he/she  is leaving in afternoon. Slowly and gradually the child used to the pattern and starts informing the teachers and maids as and  when he/ she feels the urge.",
            "order": 5
        },
        {
            "id": 7,
            "title": "About Indian Values and Culture.",
            "content": "Our country is popular for its rich traditions and cultures. At  RA we have kept a whole month to give tribute to the grand parents  as a grand parents carnival .We have also given platform to our kids to know about their rich Indian culture through Our epic character,shloka chanting ,traditional costume ,classical dance ,Rakshabandhan & Garba celebration .",
            "order": 6
        }
    ]
}
export default faqData
const aboutData ={
        "parentsreview": [
            {
                "id": 1,
                "name": "Kajal & Maulesh Patel",
                "occupation": "Businessman",
                "message": "I Think.. The principle goal of Education in the 'RANG ANGLE INTERNATION PRESCHOOL' is creating child who are capable of doing new things, not simple repeating what other have done.School's each day is full of Creativeness, Inventive and Discoverers.The fundamental purpose of this school is LEARNING not Teaching.This is the biggest difference between the 'RANG ANGLES' and the other schools.We are very THANKFULL to the CEO, DIRECTOR and TEACHERS of this school, who believe Education is not the learning of facts, but the training of the mind to THINK.",
                "imgPath": "/assets/media/def-img.webp",
                "order": 1
            },
            {
                "id": 2,
                "name": "Mrs. Varsha Kale",
                "occupation": "Entrepreneur",
                "message": "Calendar theme of your school is very nice and all the activities performed as per schedule and all teacher's doing very well job ... really appreciate..",
                "imgPath": "/assets/media/def-img.webp",
                "order": 2
            },
            {
                "id": 3,
                "name": "Anant Gadakh",
                "occupation": "Research & Development- Apicore pharma",
                "message": "Excellent ! Amazing teacher and great attention is made to students, I'm observing day to day activities of my daughter and feeling amazing to see drastic changes in her.I am fully satisfied with Rang Angels School.",
                "imgPath": "/assets/media/def-img.webp",
                "order": 3
            },
            {
                "id": 4,
                "name": "Mrs. Avani N Joshi",
                "occupation": "Works with Airtel",
                "message": "A great method to keep parents in touch with the teaching methods at school, so they can be reinforce at home.Thank you it was a great experience.",
                "imgPath": "/assets/media/def-img.webp",
                "order": 4
            },
            {
                "id": 5,
                "name": "Mrs. Sanika S. Mali",
                "occupation": "Teacher",
                "message": "Hi my daughter not able to talk before going to Rang Angel school at age of three she could not talk but now she is very much talkive This is due to highly encouraging Teachers and school environment. According to her class teacher chanting of 'aum ' during the meditation helped her a lot.",
                "imgPath": "/assets/media/def-img.webp",
                "order": 5
            },
            {
                "id": 6,
                "name": "Mrs. Rashmika Makwana",
                "occupation": "Jr. manager Scale-1 in BOB",
                "message": "Teachers play extraordinary roles in our lives.but anurag sir has played more than that.He moulded us and our future accordingly in order to make us a responsible citizen of the country.He is very polite, kind, caring and friendly in nature.He is also very disciplined and punctual.I have always enjoyed his class.He follows very easy and effective teaching strategies to teach us.He is very enthusiastic and always motivated us for doing our best in the study. I still remember him for his unique style of teaching.What he taught to me, I still remember very well as he has made my English grammar concepts very clear.Which helped me to clear many of competitive exams..and to achieve this position in my career and life.He always motivated me for UPSC exam's preparation and guided to read CSR magazines and newspapers. Sometimes, when we were not in mood of study,he shared with us about his struggle period of the life in order to encourage us towards study.",
                "imgPath": "/assets/media/def-img.webp",
                "order": 6
            },
            {
                "id": 7,
                "name": "Ms. Barkha Chouthani",
                "occupation": "C.A.",
                "message": "My Role model, Dream maker, Guide, teacher, last but not the least best philosopher, a very positive person. What to say about him !!!!!But without him I could not have reached at my goal to become a C.A. A person who is ever ready to help to all, very enthusiastic and hard working. Time is the limit not words about him.. Proud to have you in my life. Thank u sirr...",
                "imgPath": "/assets/media/def-img.webp",
                "order": 7
            }
        ],
        "meetOurTeam": [
            
            {
                "id": 1,
                "name": "Dr. Meena Dodeja Barot",
                "ocuupation": "Ph.D. in Dance",
                "information": "Shree kala kendra",
                "imgPath": "/assets/media/mediadrmina.png",
                "order": 1
            },
            {
                "id": 2,
                "name": "Dr. Minaxi Patel",
                "ocuupation": "Dietician",
                "information": "Ayurveda Doctor",
                "imgPath": "/assets/media/mediadrminaxi.png",
                "order": 2
            }
        ]
    }
export default aboutData;
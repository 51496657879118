import Router from "./Router";
import WithLayout from "./components/layouts/WithLayout";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import 'font-awesome/css/font-awesome.min.css';

import './assets/css/style.css';
import './assets/css/responsive.css';
import './assets/css/shortcodes.css';


function App() {
  return (
    <Router />
  );
}

export default WithLayout(App);

import TextField from '@mui/material/TextField';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import { Button } from '@material-ui/core';
import Files from 'react-files'
import watermark from '../../assets/images/watermark.png'
import { useState } from 'react';
import { useHistory } from "react-router-dom";

function Staffrecruitment() {
    const [fullName, setfullName] = useState(null);
    const [fullNameError, setfullNameError] = useState(false);
    const [address, setaddress] = useState(null);
    const [addressError, setaddressError] = useState(false)
    const [email, setemail] = useState(null);
    const [emailError, setemailError] = useState(false);
    const [phoneNumber, setphoneNumber] = useState(null);
    const [phoneNumberError, setphoneNumberError] = useState(false);
    const [file, setFile] = useState([]);

    const history = useHistory();

    const validEmail = /^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/;
    // eslint-disable-next-line
    const validPhoneNumber = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/;

    const onFilesChange = (files) => {
        console.log(files)
        setFile(files);
    }

    const onFilesError = (error, file) => {
        console.log('error code ' + error.code + ': ' + error.message)
    }


    const onSubmit = () => {
        if (!fullName) { setfullNameError(true); }
        if (!address) { setaddressError(true); }
        if (!email) { setemailError(true); }
        if (!phoneNumber) { setphoneNumberError(true); }

        const finalData = new FormData();
        finalData.append("name", fullName);
        finalData.append("resume", file.length > 0 ? file[0] : null);
        finalData.append("address", address);
        finalData.append("email", email);
        finalData.append("phone", phoneNumber);

        const requestOptions = {
            method: 'POST',
            body: finalData
        };
        console.log('requestOptions: ', requestOptions);

        fetch('http://localhost:8000/tmpadmin/addstaffrecuritment', requestOptions).then(response => response.json())
            .then(data => {
                console.log('data: ', data);
                if (data.status) {
                    history.push('/');
                }
            })
            .catch((error) => {
                console.log('error: ', error);
                // onSaveBtnClick();
            });
    }

    const validate = () => {
        if (!validEmail.test(email)) {
            setemailError(true)
        }
        if (!validPhoneNumber.test(phoneNumber)) {
            setphoneNumberError(true)
        }
    }

    return (
        <>
            <div className="form_container">
                <div className="form_header">
                    <div className="form_logo">
                        <img src={watermark} alt="logo" className='watermark_logo' />
                    </div>
                    <div className="form_heading">
                        <h1>SATFF RECRUITMENT FORM</h1>
                    </div>
                </div>

                <div className='form_inputs'>
                    <div className='inputs'>
                        <label className='label'>Full Name : </label>
                        <TextField
                            id="outlined-basic"
                            label="Enter Full Name"
                            variant="outlined"
                            className="txtField"
                            error={fullNameError}
                            onChange={(e) => { setfullName(e.target.value); setfullNameError(false) }}
                        />
                    </div>
                    <div className='inputs'>
                        <label className='label'>Address : </label>
                        {/* <TextField id="outlined-basic" className="address" label="Enter Address" variant="outlined" /> */}
                        <TextareaAutosize
                            aria-label="minimum height"
                            minRows={3}
                            placeholder="Enter Address"
                            style={!addressError ? { border: "1.2px solid rgb(202, 194, 194)" } : { border: "1.2px solid #d32f2f" }}
                            className='textborder'
                            onChange={(e) => { setaddress(e.target.value); setaddressError(false) }}
                        />
                    </div>
                    <div className='inputs'>
                        <label className='label'>Email Address : </label>
                        <TextField
                            id="outlined-basic"
                            label="Enter Email Address"
                            variant="outlined"
                            className="txtField"
                            error={emailError}
                            onChange={(e) => { setemail(e.target.value); setemailError(false) }}
                        />
                    </div>
                    <div className='inputs'>
                        <label className='label'>Phone Number : </label>
                        <TextField
                            id="outlined-basic"
                            label="Enter Phone Number"
                            variant="outlined"
                            className="txtField"
                            error={phoneNumberError}
                            onChange={(e) => { setphoneNumber(e.target.value); setphoneNumberError(false) }}
                        />
                    </div>
                    <div className='inputs'>
                        <label className='label'>Upload your CV : </label>
                        <div className="files">
                            <Files
                                className='files-dropzone'
                                onChange={onFilesChange}
                                onError={onFilesError}
                                accepts={['image/png', '.pdf', 'audio/*']}
                                multiple
                                maxFileSize={10000000}
                                minFileSize={0}
                                clickable
                            >
                                Choose file
                            </Files>
                        </div>
                        {
                            file.length > 0 ?
                                file[0].name
                                : null
                        }
                    </div>
                    <Button variant="contained" color="primary" className='submit'
                        onClick={() => { onSubmit(); validate(); }}
                    >
                        Submit
                    </Button>
                </div>
            </div>
        </>
    )
}

export default Staffrecruitment;
import IMG20171116124613 from "../../assets/images/act/howdoweteach/IMG20171116124613.jpg";
// import IMG20171121101904 from "../../assets/images/act/howdoweteach/IMG20171121101904.jpg";

function TeacherTraning() {
  return (
    <>
      <section className="fullwidth_banner">
        <div className="container">
          <h1>Teacher's Training Program</h1>
        </div>
      </section>

      <section
        id="primary"
        style={{ marginTop: 0 }}
        className=" content-full-width"
      >
        <section className="fullwidth-background dt-sc-parallax-section turquoise-bg">
          <div className="container">
            <div className="dt-sc-one-half column first">
              <p style={{ color: "white", textAlign: "justify" }}>
                We have our own Advanced ECCE training institute which is
                specially designed for those who wants to choose their best
                future in the field of preschool. We provides seven steps of
                effective teaching methods to our teachers. Its one year
                program. which is specially designed by our professional team.
              </p>
              <h4 style={{ color: "white" }}>
                Its 10 months course which includes :
              </h4>
              <div className="dt-sc-one-half column first">
                <div
                  className="dt-sc-ico-content type2"
                  style={{ width: "100%" }}
                >
                  <div className="icon">
                    <span> 1 </span>
                  </div>
                  <h4 style={{ color: "white" }}>ECCE</h4>
                </div>
                <div className="dt-sc-hr-very-small"></div>
                <div
                  className="dt-sc-ico-content type2"
                  style={{ width: "100%" }}
                >
                  <div className="icon">
                    <span> 2 </span>
                  </div>
                  <h4 style={{ color: "white" }}> Personality development</h4>
                </div>
                <div className="dt-sc-hr-very-small"></div>
                <div
                  className="dt-sc-ico-content type2"
                  style={{ width: "100%" }}
                >
                  <div className="icon">
                    <span> 3 </span>
                  </div>
                  <h4 style={{ color: "white" }}> Computer literacy</h4>
                </div>
                <div className="dt-sc-hr-very-small"></div>
                <div
                  className="dt-sc-ico-content type2"
                  style={{ width: "100%" }}
                >
                  <div className="icon">
                    <span> 4 </span>
                  </div>
                  <h4 style={{ color: "white" }}> Intern Ship Training </h4>
                </div>
              </div>
              <div className="dt-sc-one-half column">
                <div className="dt-sc-hr-very-small"></div>
                <div
                  className="dt-sc-ico-content type2"
                  style={{ width: "100%" }}
                >
                  <div className="icon">
                    <span> 5 </span>
                  </div>
                  <h4 style={{ color: "white" }}> English fluency</h4>
                </div>
                <div className="dt-sc-hr-very-small"></div>
                <div
                  className="dt-sc-ico-content type2"
                  style={{ width: "100%" }}
                >
                  <div className="icon">
                    <span> 6 </span>
                  </div>
                  <h4 style={{ color: "white" }}> 100% Job Placement</h4>
                </div>
                <div className="dt-sc-hr-very-small"></div>
                <div
                  className="dt-sc-ico-content type2"
                  style={{ width: "100%" }}
                >
                  <div className="icon">
                    <span> 7 </span>
                  </div>
                  <h4 style={{ color: "white" }}> In-House Training </h4>
                </div>
              </div>
            </div>
            <div className="dt-sc-one-half column">
              <h2>How do we Teach To our Students?</h2>
              <div className="add-slider-wrapper">
                {/* teacherTraining */}
                <ul className="add-slider">
                  <li>
                    {" "}
                    <img src={IMG20171116124613} alt="" title="" />{" "}
                  </li>
                  {/* <li> <img src={IMG20171121101904} alt="" title="" /> </li> */}
                </ul>
              </div>
            </div>
          </div>
        </section>
      </section>
    </>
  );
}

export default TeacherTraning;

import TextField from "@mui/material/TextField";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import Button from "@mui/material/Button";
import watermark from "../../assets/images/watermark.png";
import { useState } from "react";
import { useHistory } from "react-router-dom";

function Franchiseform() {
  const history = useHistory();

  const [fullName, setfullName] = useState(null);
  const [fullNameError, setfullNameError] = useState(false);
  const [organizationName, setorganizationName] = useState(null);
  const [organizationNameError, setorganizationNameError] = useState(false);
  const [address, setaddress] = useState(null);
  const [addressError, setaddressError] = useState(false);
  const [email, setemail] = useState(null);
  const [emailError, setemailError] = useState(false);
  const [phoneNumber, setphoneNumber] = useState(null);
  const [phoneNumberError, setphoneNumberError] = useState(false);
  const [location, setlocation] = useState(null);
  const [locationError, setlocationError] = useState(false);
  const [comments, setcomments] = useState(null);
  const [commentsError, setcommentsError] = useState(false);

  const validEmail = /^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/;
  // eslint-disable-next-line
  const validPhoneNumber = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/;

  const onSubmit = () => {
    if (!fullName) {
      setfullNameError(true);
    }
    if (!organizationName) {
      setorganizationNameError(true);
    }
    if (!address) {
      setaddressError(true);
    }
    if (!email) {
      setemailError(true);
    }
    if (!phoneNumber) {
      setphoneNumberError(true);
    }
    if (!location) {
      setlocationError(true);
    }
    if (!comments) {
      setcommentsError(true);
    }

    const finalData = new FormData();
    finalData.append("name", fullName);
    finalData.append("organization", organizationName);
    finalData.append("address", address);
    finalData.append("email", email);
    finalData.append("phone", phoneNumber);
    finalData.append("location", location);
    finalData.append("comments", comments);

    const requestOptions = {
      method: "POST",
      body: finalData,
    };
    console.log("requestOptions: ", requestOptions);

    fetch("http://localhost:8000/tmpadmin/addfranchiseeform", requestOptions)
      .then((response) => response.json())
      .then((data) => {
        console.log("data: ", data);
        if (data.status) {
          history.push("/");
        }
      })
      .catch((error) => {
        console.log("error: ", error);
        // onSaveBtnClick();
      });
  };

  const validate = () => {
    if (!validEmail.test(email)) {
      setemailError(true);
    }
    if (!validPhoneNumber.test(phoneNumber)) {
      setphoneNumberError(true);
    }
  };

  return (
    <>
      <div className="franchise_requirment">
        <div>
          <h1 className="form_title">
            RANG ANGELS PRE SCHOOL FRANCHISE PROGRAM
          </h1>
          <p className="form_text">
            RAIS offers the highly satisfying, most promising, profitable and
            best rewarding pre-school franchise business opportunity to the
            entrepreneurs with an invitation to be a part of the fastest growing
            Indian education sector. RAIS invites the women entrepreneurs,
            individuals, institutions, registered societies and the owners of
            the individual primary nursery elementary schools in cities and
            towns to be a part of the India’s fastest growing pre-schools by
            establishing ‘RANG ANGELS ’ brand of franchise play school. The
            pre-school franchise seekers must have a strong desire to make a
            difference to the society and sincerity towards the noble profession
            of providing high quality early childhood education at affordable
            costs with Us.
          </p>
        </div>
        <div>
          <h1 className="form_title">
            HIGH QUALITY EDUCATION AT AFFORDABLE COSTS:{" "}
          </h1>
          <p className="form_text">
            According to a business research in Indian Early Childhood Education
            sector, the pre schools in India offering high quality education at
            affordable cost for the preschool kids of the lower and middle class
            population. The highly experienced early childhood education experts
            and the education sector business management experts at RAIS have
            developed an affordable model of pre-school franchise requiring low
            investment for the establishment and functioning of the pre-school
            without compromising on the high quality learning. The low
            investment preschool model translates into offering a best
            efficient, responsible, affordable and top quality pre-school
            learning system for the children at RAIS.
          </p>
        </div>
        <div>
          <h1 className="form_title">
            TOP RATED FEATURES OF BLESS KIDS PRESCHOOL FRANCHISE:
          </h1>
          <p className="form_text">
            • Successfully replicated, Low investment Pre-school franchise
            projects • Lowest Royalty fee, higher profits for franchisee • Low
            business risks involved in opening a Pre-school & the Operations. •
            Best returns on Investment in Pre-school project establishment.
          </p>
        </div>
        <div>
          <h1 className="form_title">
            TENTATIVE INVESTMENT REQUIRED FOR PRE-SCHOOL
          </h1>
          <p className="form_text">
            FRANCHISE FOR PRE-SCHOOL : In City : 8 Lacs To 10 Lacs. In
            Town/Rural Areas : 6 Lacs To 8 Lacs.
          </p>
          <p className="form_text">
            Space Requirement : Total area of minimum 3000 and above sq. ft.
            with an additional open space.
          </p>
        </div>
        <div>
          <h1 className="form_title">
            RAIS SUPPORT FOR PRE SCHOOL FRANCHISEE :
          </h1>
          <p className="form_text">
            • Set up assistance by experts to establish and operate a successful
            preschool • International standard ambience, equipments, gadgets &
            infrastructure • Expert help for quick launch of preschool project &
            short break even period • Corporate branding, advertising on local,
            regional and national level • Effective promotional material & tips,
            training on marketing & management • Training provided on business,
            operations, administration & software • Expert support for academic
            excellence, accounting, feedback & security • Assistance for
            recruitment & training of teaching faculty and support staff •
            Training on curriculum, office automation, organizing events and
            activities • Personalized guidance & support system by experts for
            easier operations.
          </p>
        </div>
      </div>
      <hr className="form_hr"></hr>
      <div className="form_container">
        <div className="form_header">
          <div className="form_logo">
            <img src={watermark} alt="logo" className="watermark_logo" />
          </div>
          <div className="form_heading">
            <h1>RAIS FRANCHISEE FORM</h1>
          </div>
        </div>

        <div className="watermark">
          <img src={watermark} alt="logo" />
        </div>

        <div className="form_inputs">
          <div className="inputs">
            <label className="label">Full Name : </label>
            <TextField
              id="outlined-basic"
              label="Enter Full Name"
              variant="outlined"
              className="txtField"
              error={fullNameError}
              onChange={(e) => {
                setfullName(e.target.value);
                setfullNameError(false);
              }}
            />
          </div>
          <div className="inputs">
            <label className="label">Organisation : </label>
            <TextField
              id="outlined-basic"
              label="Enter Organisation Name"
              variant="outlined"
              className="txtField"
              error={organizationNameError}
              onChange={(e) => {
                setorganizationName(e.target.value);
                setorganizationNameError(false);
              }}
            />
          </div>
          <div className="inputs">
            <label className="label">Address : </label>
            {/* <TextField id="outlined-basic" className="address" label="Enter Address" variant="outlined" /> */}
            <TextareaAutosize
              aria-label="minimum height"
              minRows={3}
              placeholder="Enter Address"
              style={
                !addressError
                  ? { border: "1.2px solid rgb(202, 194, 194)", width: "93%" }
                  : { border: "1.2px solid #d32f2f", width: "93%" }
              }
              className="textborder"
              onChange={(e) => {
                setaddress(e.target.value);
                setaddressError(false);
              }}
            />
          </div>
          <div className="inputs">
            <label className="label">Email Address : </label>
            <TextField
              id="outlined-basic"
              label="Enter Email Address"
              variant="outlined"
              className="txtField"
              error={emailError}
              onChange={(e) => {
                setemail(e.target.value);
                setemailError(false);
              }}
            />
          </div>
          <div className="inputs">
            <label className="label">Phone Number : </label>
            <TextField
              id="outlined-basic"
              label="Enter Phone Number"
              variant="outlined"
              className="txtField"
              error={phoneNumberError}
              onChange={(e) => {
                setphoneNumber(e.target.value);
                setphoneNumberError(false);
              }}
            />
          </div>
          <div className="inputs">
            <label className="label">Location : </label>
            <TextField
              id="outlined-basic"
              label="Enter Location"
              variant="outlined"
              className="txtField"
              error={locationError}
              onChange={(e) => {
                setlocation(e.target.value);
                setlocationError(false);
              }}
            />
          </div>
          <div className="inputs">
            <label className="label">Comments : </label>
            <TextareaAutosize
              aria-label="minimum height"
              minRows={3}
              placeholder="Enter Comments"
              style={
                !commentsError
                  ? { border: "1.2px solid rgb(202, 194, 194)", width: "93%" }
                  : { border: "1.2px solid #d32f2f", width: "93%" }
              }
              className="textborder"
              onChange={(e) => {
                setcomments(e.target.value);
                setcommentsError(false);
              }}
            />
            {/* <TextField id="outlined-basic" className="address" label="Enter Comments" variant="outlined" /> */}
          </div>
          <Button
            variant="contained"
            className="submit"
            onClick={() => {
              onSubmit();
              validate();
            }}
          >
            Submit
          </Button>
        </div>
      </div>
    </>
  );
}

export default Franchiseform;

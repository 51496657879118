import { useEffect, useState } from "react";
import fascinationData from "./data/Fascinationsdata";
function Fascinations() {
  const [fascinations, setfascinations] = useState([]);

  useEffect(() => {
    splitIntoChunk(fascinationData["fascinations"], 4);
    // eslint-disable-next-line
  }, []);

 

  const splitIntoChunk = (arr, chunk) => {
    let obj = {};
    let finalData = [];
    for (let i = 0; i < arr.length; i += chunk) {
      let tempArray;
      tempArray = arr.slice(i, i + chunk);
      obj = {
        row: tempArray,
      };
      finalData.push(obj);
    }
    setfascinations(finalData);
  };

  return (
    <>
      <section className="fullwidth_banner">
        <div className="container">
          <h1>Our Fascinations</h1>
        </div>
      </section>

      <div className="container">
        <section id="primary" className="content-full-width">
          {/* <div className="container"> */}
          {fascinations &&
            fascinations.length > 0 &&
            fascinations.map((item, index) => (
              <div key={index}>
                <div className="container">
                  {index === 0 ? (
                    <h2 className="dt-sc-hr-green-title">
                      {" "}
                      Activities for Children{" "}
                    </h2>
                  ) : null}
                  {item.row.map((itm, idx) => (
                    <div
                      className={
                        "column dt-sc-one-fourth " + (idx === 0 ? "first" : "")
                      }
                      key={idx}
                    >
                      <div className={"activity " + itm.class1}>
                        <h4> {itm.title}</h4>
                        <img
                          src={itm.imgPath}
                          alt=""
                          title=""
                          className="fascination_img"
                        />
                        <p>{itm.content}</p>
                      </div>
                    </div>
                  ))}
                </div>
                <div className="dt-sc-hr"></div>
              </div>
            ))}
          <div className="dt-sc-hr"></div>
        </section>
      </div>
    </>
  );
}

export default Fascinations;

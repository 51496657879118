import { useEffect, useState } from "react";
import $ from "jquery";
import faqData from "./data/Faqdata";
function Faq() {
  const [faqs, setfaqs] = useState([]);

  useEffect(() => {
    setfaqs(faqData["faqs"]);
    // eslint-disable-next-line
  }, []);

  

  useEffect(() => {
    $(".dt-sc-toggle").toggle(
      function () {
        $(this).addClass("active");
      },
      function () {
        $(this).removeClass("active");
      }
    );
    $(".dt-sc-toggle").click(function () {
      $(this).next(".dt-sc-toggle-content").slideToggle();
    });
    $(".dt-sc-toggle-frame-set").each(function () {
      var $this = $(this),
        $toggle = $this.find(".dt-sc-toggle-accordion");

      $toggle.click(function () {
        if ($(this).next().is(":hidden")) {
          $this
            .find(".dt-sc-toggle-accordion")
            .removeClass("active")
            .next()
            .slideUp();
          $(this).toggleClass("active").next().slideDown();
        }
        return false;
      });

      //Activate First Item always
      $this.find(".dt-sc-toggle-accordion:first").addClass("active");
      $this.find(".dt-sc-toggle-accordion:first").next().slideDown();
    });
  });

  return (
    <>
      <section className="fullwidth_banner">
        <div className="container">
          <h1>Frequently Asked Question</h1>
        </div>
      </section>

      <section id="primary" className="content-full-width">
        <div className="container">
          <div className="column dt-sc-one-half" style={{ width: "100%" }}>
            <div className="dt-sc-toggle-frame-set course">
              {faqs &&
                faqs.length > 0 &&
                faqs.map((item, index) => (
                  <div key={index}>
                    <h5
                      className={
                        "dt-sc-toggle-accordion " +
                        (index === 0 ? "active" : "")
                      }
                    >
                      <div className="faqTitle">{item.title}</div>
                    </h5>
                    <div className="dt-sc-toggle-content">
                      <div className="block">
                        <p>{item.content}</p>
                        {index === 0 ? (
                          <>
                            <li> Physical Development </li>
                            <li> Intellectual Development</li>
                            <li> Language Development </li>
                            <li> Socio –Emotional Development </li>
                            <li> Creative Development</li>
                          </>
                        ) : null}
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>

        <div className="dt-sc-hr"></div>
      </section>
    </>
  );
}

export default Faq;

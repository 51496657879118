import $ from "jquery";
import { useState } from "react";
import TextField from "@mui/material/TextField";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControl from "@mui/material/FormControl";
import ImageUploading from "react-images-uploading";
import { Button } from "@material-ui/core";
import { useHistory } from "react-router-dom";

function Studentregistartion() {
  const [firstName, setfirstName] = useState(null);
  const [lastName, setLastName] = useState(null);
  const [birthday, setBirthday] = useState(null);
  const [age, setAge] = useState(null);
  const [nationality, setNationality] = useState(null);
  const [bloodgroup, setBloodgroup] = useState(null);
  const [address, setAddress] = useState(null);
  const [siblingname, setSiblingName] = useState(null);
  const [siblingage, setSiblingAge] = useState(null);
  const [siblingschool, setSiblingSchool] = useState(null);
  const [siblingclass, setSiblingClass] = useState(null);
  const [fathername, setFathername] = useState(null);
  const [mothername, setMothername] = useState(null);
  const [fatheroccupation, setFatheroccupation] = useState(null);
  const [motheroccupation, setMotheroccupation] = useState(null);
  const [motherorganization, setMotherorganization] = useState(null);
  const [fatherorganization, setFatherorganization] = useState(null);
  const [fatherphone, setFatherphone] = useState(null);
  const [motherphone, setMotherphone] = useState(null);
  const [fatherfax, setFatherfax] = useState(null);
  const [motherfax, setMotherfax] = useState(null);
  const [fathermobile, setFathermobile] = useState(null);
  const [mothermobile, setMothermobile] = useState(null);
  const [fatheremail, setFatheremail] = useState(null);
  const [motheremail, setMotheremail] = useState(null);

  const [value, setValue] = useState("");
  const [valueadmission, setValueadmission] = useState("");
  const [valuekinder, setValuekinder] = useState("");
  const [images, setImages] = useState([]);
  const [fatherimg, setFatherimg] = useState([]);
  const [motherimg, setMotherimg] = useState([]);

  const history = useHistory();

  const onAddRowClick = (event) => {
    event.preventDefault();
    var name = siblingname;
    var email = siblingage;
    var school = siblingschool;
    var Class = siblingclass;
    if (name !== "") {
      var markup =
        "<tr><td><input type='checkbox' name='record'></td><td>" +
        name +
        "</td><td>" +
        email +
        "</td><td>" +
        school +
        "</td><td>" +
        Class +
        "</td></tr>";
      $("table tbody").append(markup);
    }
  };

  const onDeleteRowClick = (event) => {
    event.preventDefault();
    $("table tbody")
      .find('input[name="record"]')
      .each(function () {
        if ($(this).is(":checked")) {
          $(this).parents("tr").remove();
        }
      });
  };

  const handleRadio = (event) => {
    setValue(event.target.value);
  };

  const handleRadioAdmission = (event) => {
    setValueadmission(event.target.value);
  };

  const handleRadioKinder = (event) => {
    setValuekinder(event.target.value);
  };

  const maxNumber = 1;
  const onChange = (imageList, addUpdateIndex) => {
    console.log(imageList, addUpdateIndex);
    setImages(imageList);
  };

  const onFatherChange = (imageList, addUpdateIndex) => {
    console.log(imageList, addUpdateIndex);
    setFatherimg(imageList);
  };

  const onMotherChange = (imageList, addUpdateIndex) => {
    console.log(imageList, addUpdateIndex);
    setMotherimg(imageList);
  };

  const onSaveBtnClick = () => {
    const finalData = new FormData();
    finalData.append("admissionto", valueadmission);
    finalData.append("kindergarten", valuekinder);
    finalData.append("firstname", firstName);
    finalData.append("lastname", lastName);
    finalData.append("gender", value);
    finalData.append("birthdate", birthday);
    finalData.append("age", age);
    finalData.append("nationality", nationality);
    finalData.append("bloodgroup", bloodgroup);
    finalData.append("address", address);
    finalData.append("fathername", fathername);
    finalData.append("mothername", mothername);
    finalData.append("fatheroccupation", fatheroccupation);
    finalData.append("motheroccupation", motheroccupation);
    finalData.append("fatherorganization", fatherorganization);
    finalData.append("motherorganization", motherorganization);
    finalData.append("fatherphone", fatherphone);
    finalData.append("motherphone", motherphone);
    finalData.append("fatherfax", fatherfax);
    finalData.append("motherfax", motherfax);
    finalData.append("fathermobile", fathermobile);
    finalData.append("mothermobile", mothermobile);
    finalData.append("fatheremail", fatheremail);
    finalData.append("motheremail", motheremail);

    finalData.append("photo", images.length > 0 ? images[0].file : null);
    finalData.append(
      "fatherphoto",
      fatherimg.length > 0 ? fatherimg[0].file : null
    );
    finalData.append(
      "motherphoto",
      motherimg.length > 0 ? motherimg[0].file : null
    );

    const requestOptions = {
      method: "POST",
      body: finalData,
    };
    console.log("requestOptions: ", requestOptions);

    fetch(
      "http://localhost:8000/tmpadmin/addstudentregistration",
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        console.log("data: ", data);
        if (data.status) {
          history.push("/");
        }
      })
      .catch((error) => {
        console.log("error: ", error);
        // onSaveBtnClick();
      });
  };

  return (
    <>
      <section className="fullwidth_banner">
        <div className="container">
          <h1>Student Registration</h1>
        </div>
      </section>
      <div className="container">
        <section id="primary" className="content-full-width">
          <div className="column dt-sc-one-half first">
            <h4>Application for admission to:</h4>
            <FormControl>
              <RadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                value={valueadmission}
                onChange={handleRadioAdmission}
                className="student_radio"
              >
                <FormControlLabel
                  value="Rangtoons"
                  control={<Radio />}
                  label="Rangtoons 1 to 2 Years"
                />
                <br />
                <FormControlLabel
                  value="PlayGroup"
                  control={<Radio />}
                  label="Play Group 1.5 to 2.5 Years"
                />
                <br />
                <FormControlLabel
                  value="MaNursery"
                  control={<Radio />}
                  label="Nursery 2.5 to 3.5 Years"
                />
                <br />
                <FormControlLabel
                  value="DayCare"
                  control={<Radio />}
                  label="Day Care 1 to 8 Years"
                />
                <br />
              </RadioGroup>
            </FormControl>

            <br />
          </div>

          <div className="column dt-sc-one-fourth">
            <h4>Kindergarten:</h4>
            <FormControl>
              <RadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                value={valuekinder}
                onChange={handleRadioKinder}
                className="student_radio"
              >
                <FormControlLabel
                  value="AdvancedJunior"
                  control={<Radio />}
                  label="Advanced Junior K.G 3.5 to 4.5 Years"
                />
                <br />
                <FormControlLabel
                  value="AdvancedSenior"
                  control={<Radio />}
                  label="Advanced Senior K.G 4.5 to 5.5 Years"
                />
                <br />
              </RadioGroup>
            </FormControl>
          </div>

          <div className="column dt-sc-one-fourth">
            <label className="student_l">Upload Photo:</label>
            <ImageUploading
              multiple
              value={images}
              onChange={onChange}
              maxNumber={maxNumber}
              dataURLKey="data_url"
            >
              {({
                imageList,
                onImageUpload,
                onImageRemove,
                isDragging,
                dragProps,
              }) => (
                // write your building UI
                <div className="upload__image-wrapper">
                  {imageList.length === 0 ? (
                    <Button
                      variant="outlined"
                      color="primary"
                      style={isDragging ? { color: "red" } : null}
                      onClick={onImageUpload}
                      {...dragProps}
                    >
                      Click or Drop here
                    </Button>
                  ) : null}
                  &nbsp;
                  {imageList.map((image, index) => (
                    <div key={index} className="image-item">
                      <img src={image.data_url} alt="" width="150" />
                      <div className="image-item__btn-wrapper">
                        {/* <Button onClick={() => onImageUpdate(index)} variant="outlined" color="primary">Update</Button> */}
                        <Button
                          onClick={() => onImageRemove(index)}
                          variant="outlined"
                          color="primary"
                          style={{ marginTop: "15px" }}
                        >
                          Remove
                        </Button>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </ImageUploading>
          </div>
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />

          <h4>APPLICATION DETAILS</h4>
          <div className="student_name">
            <div className="student_label">
              <label className="student_l">First Name:</label>
              <TextField
                id="outlined-basic"
                label="First Name"
                variant="outlined"
                className="txtField_contact"
                onChange={(e) => {
                  setfirstName(e.target.value);
                }}
              />
            </div>
            <div className="student_label">
              <label className="student_l">Last Name:</label>
              <TextField
                id="outlined-basic"
                label="Last Name"
                variant="outlined"
                className="txtField_contact"
                onChange={(e) => {
                  setLastName(e.target.value);
                }}
              />
            </div>
          </div>
          <br />
          <label className="student_l" style={{ marginRight: "2rem" }}>
            Gender:
          </label>
          <FormControl>
            <RadioGroup
              aria-labelledby="demo-controlled-radio-buttons-group"
              name="controlled-radio-buttons-group"
              value={value}
              onChange={handleRadio}
              className="student_radio"
            >
              <FormControlLabel
                value="female"
                control={<Radio />}
                label="Female"
              />
              <br />
              <FormControlLabel value="male" control={<Radio />} label="Male" />
            </RadioGroup>
          </FormControl>

          <br />
          <br />

          <div className="student_name">
            <div className="student_label">
              <label className="student_l">Date of Birth:</label>
              <TextField
                id="outlined-basic"
                label="Date of Birth"
                variant="outlined"
                className="txtField_contact"
                onChange={(e) => {
                  setBirthday(e.target.value);
                }}
              />
            </div>
            <div className="student_label">
              <label className="student_l">Age:</label>
              <TextField
                id="outlined-basic"
                label="Age"
                variant="outlined"
                className="txtField_contact"
                onChange={(e) => {
                  setAge(e.target.value);
                }}
              />
            </div>
          </div>
          <br />

          <div className="student_name">
            <div className="student_label">
              <label className="student_l">Nationality:</label>
              <TextField
                id="outlined-basic"
                label="Nationality"
                variant="outlined"
                className="txtField_contact"
                onChange={(e) => {
                  setNationality(e.target.value);
                }}
              />
            </div>
            <div className="student_label">
              <label className="student_l">Blood Group:</label>
              <TextField
                id="outlined-basic"
                label="Blood Group"
                variant="outlined"
                className="txtField_contact"
                onChange={(e) => {
                  setBloodgroup(e.target.value);
                }}
              />
            </div>
          </div>
          <br />
          <br />
          <div className="student_name">
            <label className="student_l">Home Address: </label>
            <TextareaAutosize
              aria-label="minimum height"
              minRows={3}
              placeholder="Enter Comments"
              style={{ border: "1.2px solid rgb(202, 194, 194)" }}
              className="textborder student_address"
              onChange={(e) => {
                setAddress(e.target.value);
              }}
            />
          </div>
          <br />
          <br />
          <br />
          <div className="container">
            <form>
              <h5>SIBLING:</h5>
              <div className="student_name">
                <div className="student_sibling">
                  <label className="student_l">Name:</label>
                  <TextField
                    id="outlined-basic"
                    label="Name"
                    variant="outlined"
                    className="txtField_sibling"
                    onChange={(e) => {
                      setSiblingName(e.target.value);
                    }}
                  />
                </div>
                <div className="student_sibling">
                  <label className="student_l">Age:</label>
                  <TextField
                    id="outlined-basic"
                    label="Age"
                    variant="outlined"
                    className="txtField_sibling"
                    onChange={(e) => {
                      setSiblingAge(e.target.value);
                    }}
                  />
                </div>
                <div className="student_sibling">
                  <label className="student_l">School:</label>
                  <TextField
                    id="outlined-basic"
                    label="School"
                    variant="outlined"
                    className="txtField_sibling"
                    onChange={(e) => {
                      setSiblingSchool(e.target.value);
                    }}
                  />
                </div>
                <div className="student_sibling">
                  <label className="student_l">Class:</label>
                  <TextField
                    id="outlined-basic"
                    label="Class"
                    variant="outlined"
                    className="txtField_sibling"
                    onChange={(e) => {
                      setSiblingClass(e.target.value);
                    }}
                  />
                </div>
              </div>
              <button
                type="button"
                className="add-row"
                style={{
                  margin: "15px",
                  backgroundColor: "#45a9f7",
                  color: "white",
                }}
                onClick={(event) => onAddRowClick(event)}
              >
                Add Row
              </button>
            </form>
            <table>
              <thead>
                <tr>
                  <th>Select</th>
                  <th>Name</th>
                  <th>Age</th>
                  <th>School</th>
                  <th>Class</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
              </tbody>
            </table>
            <button
              type="button"
              className="delete-row"
              style={{ backgroundColor: "#f7c44e", color: "white" }}
              onClick={(event) => onDeleteRowClick(event)}
            >
              Delete Row
            </button>
          </div>
          <br />
          <br />
          <div className="responsive">
            <h5>PARENT'S DETAILS :</h5>
            <div className="parent_details">
              <div className="student_name">
                <div className="student_sibling">
                  <label className="student_l">Father Name:</label>
                  <TextField
                    id="outlined-basic"
                    label="Father Name"
                    variant="outlined"
                    className="txtField_parent"
                    onChange={(e) => {
                      setFathername(e.target.value);
                    }}
                  />
                </div>
                <div className="student_sibling">
                  <label className="student_l">Mother Name:</label>
                  <TextField
                    id="outlined-basic"
                    label="Mother Name"
                    variant="outlined"
                    className="txtField_parent"
                    onChange={(e) => {
                      setMothername(e.target.value);
                    }}
                  />
                </div>
              </div>
              <div className="student_name">
                <div className="student_sibling">
                  <label className="student_l">Father Occupation:</label>
                  <TextField
                    id="outlined-basic"
                    label="Occupation"
                    variant="outlined"
                    className="txtField_parent"
                    onChange={(e) => {
                      setFatheroccupation(e.target.value);
                    }}
                  />
                </div>
                <div className="student_sibling">
                  <label className="student_l">Mother Occupation:</label>
                  <TextField
                    id="outlined-basic"
                    label="Occupation"
                    variant="outlined"
                    className="txtField_parent"
                    onChange={(e) => {
                      setMotheroccupation(e.target.value);
                    }}
                  />
                </div>
              </div>
              <div className="student_name">
                <div className="student_sibling">
                  <label className="student_l">Father organization:</label>
                  <TextField
                    id="outlined-basic"
                    label="organization"
                    variant="outlined"
                    className="txtField_parent"
                    onChange={(e) => {
                      setFatherorganization(e.target.value);
                    }}
                  />
                </div>
                <div className="student_sibling">
                  <label className="student_l">Mother organization:</label>
                  <TextField
                    id="outlined-basic"
                    label="organization"
                    variant="outlined"
                    className="txtField_parent"
                    onChange={(e) => {
                      setMotherorganization(e.target.value);
                    }}
                  />
                </div>
              </div>
              <div className="student_name">
                <div className="student_sibling">
                  <label className="student_l">Father Phone(s):</label>
                  <TextField
                    id="outlined-basic"
                    label="Phone(s)"
                    variant="outlined"
                    className="txtField_parent"
                    onChange={(e) => {
                      setFatherphone(e.target.value);
                    }}
                  />
                </div>
                <div className="student_sibling">
                  <label className="student_l">Mother Phone(s):</label>
                  <TextField
                    id="outlined-basic"
                    label="Phone(s)"
                    variant="outlined"
                    className="txtField_parent"
                    onChange={(e) => {
                      setMotherphone(e.target.value);
                    }}
                  />
                </div>
              </div>
              <div className="student_name">
                <div className="student_sibling">
                  <label className="student_l">Father Fax:</label>
                  <TextField
                    id="outlined-basic"
                    label="Fax"
                    variant="outlined"
                    className="txtField_parent"
                    onChange={(e) => {
                      setFatherfax(e.target.value);
                    }}
                  />
                </div>
                <div className="student_sibling">
                  <label className="student_l">Mother Fax:</label>
                  <TextField
                    id="outlined-basic"
                    label="Fax"
                    variant="outlined"
                    className="txtField_parent"
                    onChange={(e) => {
                      setMotherfax(e.target.value);
                    }}
                  />
                </div>
              </div>
              <div className="student_name">
                <div className="student_sibling">
                  <label className="student_l">Father Mobile:</label>
                  <TextField
                    id="outlined-basic"
                    label="Mobile"
                    variant="outlined"
                    className="txtField_parent"
                    onChange={(e) => {
                      setFathermobile(e.target.value);
                    }}
                  />
                </div>
                <div className="student_sibling">
                  <label className="student_l">Mother Mobile:</label>
                  <TextField
                    id="outlined-basic"
                    label="Mobile"
                    variant="outlined"
                    className="txtField_parent"
                    onChange={(e) => {
                      setMothermobile(e.target.value);
                    }}
                  />
                </div>
              </div>
              <div className="student_name">
                <div className="student_sibling">
                  <label className="student_l">Father Email:</label>
                  <TextField
                    id="outlined-basic"
                    label="Email"
                    variant="outlined"
                    className="txtField_parent"
                    onChange={(e) => {
                      setFatheremail(e.target.value);
                    }}
                  />
                </div>
                <div className="student_sibling">
                  <label className="student_l">Mother Email:</label>
                  <TextField
                    id="outlined-basic"
                    label="Email"
                    variant="outlined"
                    className="txtField_parent"
                    onChange={(e) => {
                      setMotheremail(e.target.value);
                    }}
                  />
                </div>
              </div>
              <div className="student_name">
                <label className="student_l">Father's Photo:</label>
                <ImageUploading
                  multiple
                  value={fatherimg}
                  onChange={onFatherChange}
                  maxNumber={maxNumber}
                  dataURLKey="data_url"
                >
                  {({
                    imageList,
                    onImageUpload,
                    onImageRemove,
                    isDragging,
                    dragProps,
                  }) => (
                    // write your building UI
                    <div className="upload__image-wrapper">
                      {imageList.length === 0 ? (
                        <Button
                          variant="outlined"
                          color="primary"
                          style={isDragging ? { color: "red" } : null}
                          onClick={onImageUpload}
                          {...dragProps}
                        >
                          Click or Drop here
                        </Button>
                      ) : null}
                      &nbsp;
                      {imageList.map((image, index) => (
                        <div key={index} className="image-item">
                          <img src={image.data_url} alt="" width="150" />
                          <div className="image-item__btn-wrapper">
                            {/* <Button onClick={() => onImageUpdate(index)} variant="outlined" color="primary">Update</Button> */}
                            <Button
                              onClick={() => onImageRemove(index)}
                              variant="outlined"
                              color="primary"
                              style={{ marginTop: "15px" }}
                            >
                              Remove
                            </Button>
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                </ImageUploading>
                <label className="student_l">Mother's Photo:</label>
                <ImageUploading
                  multiple
                  value={motherimg}
                  onChange={onMotherChange}
                  maxNumber={maxNumber}
                  dataURLKey="data_url"
                >
                  {({
                    imageList,
                    onImageUpload,
                    onImageRemove,
                    isDragging,
                    dragProps,
                  }) => (
                    // write your building UI
                    <div className="upload__image-wrapper">
                      {imageList.length === 0 ? (
                        <Button
                          variant="outlined"
                          color="primary"
                          style={isDragging ? { color: "red" } : null}
                          onClick={onImageUpload}
                          {...dragProps}
                        >
                          Click or Drop here
                        </Button>
                      ) : null}
                      &nbsp;
                      {imageList.map((image, index) => (
                        <div key={index} className="image-item">
                          <img src={image.data_url} alt="" width="150" />
                          <div className="image-item__btn-wrapper">
                            {/* <Button onClick={() => onImageUpdate(index)} variant="outlined" color="primary">Update</Button> */}
                            <Button
                              onClick={() => onImageRemove(index)}
                              variant="outlined"
                              color="primary"
                              style={{ marginTop: "15px" }}
                            >
                              Remove
                            </Button>
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                </ImageUploading>
              </div>
            </div>
          </div>
          <input
            type="button"
            name="Save"
            value="Save"
            className="dt-sc-button small mustard"
            style={{ marginBottom: "3rem" }}
            onClick={() => onSaveBtnClick()}
          />
        </section>
      </div>
    </>
  );
}

export default Studentregistartion;

import * as moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faYoutube, faInstagram, } from '@fortawesome/free-brands-svg-icons';
import { faMapMarkerAlt, faEnvelope } from "@fortawesome/free-solid-svg-icons"
function Footer() {

  let year = moment().year();

  return (
    <>

      <footer>

        <div className="footer-widgets-wrapper">

          <div className="container">

            <div className="column dt-sc-one-fourth first">
              <aside className="widget widget_text">
                <h3 className="widgettitle red_sketch"> About Rang Angels </h3>
                Rang angels is the first unique concept where Love, Care, Affection, Positivity, Health, Motivational Affirmations , Life skills have been teaching equally.Abundant love and Personal attention are received by every angels here.The journey of Rang Angels have been started from 6th April , 2017 with its first step.

              </aside>
            </div>
            <div className="column dt-sc-one-fourth">
              <aside className="widget widget_text">
                <h3 className="widgettitle steelblue_sketch">Contact</h3>
                <div className="textwidget">
                  <p className="dt-sc-contact-info"><FontAwesomeIcon icon={faMapMarkerAlt} style={{ color: "#008c99" }}/> Address :<br /><br />Rang Angels International Pre-School.<br />69, Sai Sarjan Bunglows,<br />Opp, darshanam Elite,<br />Sun Pharma Road,<br />Atladra, 390012<br />Vadodara. </p>

                </div>
              </aside>
            </div>
            <div className="column dt-sc-one-fourth">
              <aside className="widget tweetbox">
                <h3 className="widgettitle yellow_sketch">Phone</h3>
                <div className="textwidget">

                  <p className="dt-sc-contact-info"><a href="tel:+919574318880">(M) 091 - 9574318880</a> <a href="tel:+917573033362">(M) 091 - 7573033362</a></p>

                </div>
              </aside>
            </div>
            <div className="column dt-sc-one-fourth">
              <aside className="widget widget_text">
                <h3 className="widgettitle green_sketch">Email</h3>
                <div className="textwidget">

                  <p className="dt-sc-contact-info"><FontAwesomeIcon icon={faEnvelope} style={{ color: "#008c99" }}/><a href="mailto:info@rangangels.com"> info@rangangels.com </a></p>
                </div>
              </aside>
              <aside className="widget mailchimp">
                <p> We're social </p>
                <form name="frmnewsletter" className="mailchimp-form" action="" method="post">
                  <p>
                    <span className="fa fa-envelope-o"> </span>
                    <input type="email" placeholder="Email Address" name="mc_email" required />
                  </p>
                  <input type="submit" value="Subscribe" className="button" name="btnsubscribe" />
                </form>
                <div id="ajax_subscribe_msg"></div>
              </aside>
            </div>

          </div>

        </div>

        <div className="copyright">
          <div className="container">
            <p className="copyright-info">© {year} Rang Angels. All rights reserved. Developed by <a href="https://ingenious.org.in" target="_blank" rel="noreferrer" title=""> Ingenious Solutions </a></p>
            <div className="footer-links">
              <p>Follow us</p>
              <ul className="dt-sc-social-icons">
                <li className="facebook"><a href="https://www.facebook.com/RangAngelsInternationalPreSchool/" target="_blank" rel="noreferrer" ><FontAwesomeIcon icon={faFacebookF} style={{ color: "white" }} /></a></li>
                <li className="gplus"><a href="https://www.youtube.com/channel/UCmOhMw44xj-wLTGNCdovIyA" target="_blank" rel="noreferrer" ><FontAwesomeIcon icon={faYoutube} style={{ color: "white" }} /></a></li>
                <li className="instagram"><a href="https://www.instagram.com/rangangelsvadodara/" target="_blank" rel="noreferrer" ><FontAwesomeIcon icon={faInstagram} style={{ color: "white" }} /></a></li>
                {/* <li className="pinterest"><a href="/"><img src={pin} alt="" title="" /></a></li> */}
              </ul>
            </div>
          </div>
        </div>
      </footer>
    </>
  )
}

export default Footer;
import { useRef, useState, useLayoutEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, useLocation } from 'react-router-dom';
import { faFacebookF, faYoutube, faInstagram } from '@fortawesome/free-brands-svg-icons';
import $ from 'jquery';
import * as moment from 'moment';

import logo from '../../assets/images/logo.png';
import admissionOpen from "../../assets/images/admissionsOpen.gif";
import midTermAdmissionOpen from "../../assets/images/midTermAdmissionOpen.gif";

function useStickyHeader(offset = 0) {
  const [stick, setStick] = useState(false);

  const handleScroll = () => {
    setStick(window.scrollY > offset);
  };

  useLayoutEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return (() => {
      window.removeEventListener('scroll', handleScroll);
    });
  });

  return stick;
}

function Header() {

  const ref = useRef();
  const sticky = useStickyHeader(50);
  const headerClasses = `header ${(sticky) ? 'sticky' : ''}`;

  let month = moment().format('M');

  const location = useLocation();

  const menuToggleCllick = (event) => {
    event.preventDefault();
    var $menu = $("nav#main-menu").find("ul.menu:first");
    $menu.slideToggle(function () {
      $menu.css('overflow', 'visible');
      $menu.toggleClass('menu-toggle-open');
    });
  }

  const menuExpandCllick = (event, cls) => {
    event.preventDefault();
    if ($(cls).hasClass("dt-mean-clicked")) {
      $(cls).text("+");
      if ($(cls).prev('ul').length) {
        $(cls).prev('ul').slideUp(400);
      } else {
        $(cls).prev('.megamenu-child-container').find('ul:first').slideUp(600);
      }
    } else {
      $(cls).text("-");
      if ($(cls).prev('ul').length) {
        $(cls).prev('ul').slideDown(400);
      } else {
        $(cls).prev('.megamenu-child-container').find('ul:first').slideDown(2000);
      }
    }

    $(cls).toggleClass("dt-mean-clicked");
    return false;
  }

  return (
    <header>
      <div className="container p-0" style={{ display: "flex", justifyContent: "space-between" }}>
        <div className="logo">
          <Link to="/" title="Kids Life">
            <img src={logo} alt="Kids Life" title="Kids Life" className="kidsLife" />
          </Link>
        </div>

        <div className="logoR">
          <Link to="/studentregistartion" title="Admission Open">
            {
              month === '1' || month === '2' || month === '3' || month === '4' || month === '5' || month === '6' || month === '7' || month === '8' || month === '9' ?
                <img src={admissionOpen} alt="Kids Life" title="Admission Open" className="franchise" />
                :
                <img src={midTermAdmissionOpen} alt="Kids Life" title="Mid Term Admission Open" className="franchise" />
            }
          </Link>
          {/* <p className="mail">
                            <a href="/">contact@rangangels.com</a>
                            <span className="fa fa-envelope"></span>
                        </p>
                        <p className="phone-no">
                            <i>+91 95743 18880</i>
                            <span className="fa fa-phone"></span>
                        </p> */}
        </div>
      </div>

      <div id="menu-container" ref={ref} className={headerClasses}>
        <div className="container p-0" style={{ display: "flex", justifyContent: "space-between" }}>

          <nav id="main-menu">
            <div className="dt-menu-toggle" id="dt-menu-toggle" onClick={(event) => menuToggleCllick(event)}>Menu<span className="dt-menu-toggle-icon"></span></div>
            <ul id="menu-main-menu" className="menu">
              <li className={location.pathname === "/" ? "current_page_item red" : "red"}> <Link to="/"> Home </Link> </li>
              <li className={location.pathname === "/about" ? "current_page_item mustard" : "mustard"}> <Link to="/about"> About us </Link> </li>
              <li className={(location.pathname === "/curriculum" || location.pathname === "/scheduler" || location.pathname === "/teachertraning")
                ? "current_page_item menu-item-simple-parent menu-item-depth-0 green"
                : "menu-item-simple-parent menu-item-depth-0 green"}>
                {/* eslint-disable-next-line */}
                <a role="button" tabIndex="0">Programs</a>
                <ul className="sub-menu">
                  <li><Link to="/curriculum">Curriculum</Link></li>
                  <li><Link to="/scheduler">Calender/Scheduler</Link></li>
                  <li><Link to="/teachertraning">Teacher's Training Program</Link></li>
                  <li><Link to="/newsletter">Newsletter</Link></li>
                </ul>
                <Link to="/" className="dt-menu-expand programs" onClick={(event) => menuExpandCllick(event, '.programs')}>+</Link>
              </li>
              <li className={(location.pathname === "/faq" || location.pathname === "/registration")
                ? "current_page_item menu-item-simple-parent menu-item-depth-0 blue"
                : "menu-item-simple-parent menu-item-depth-0 blue"}>
                {/* eslint-disable-next-line */}
                <a role="button" tabIndex="0">Registration and Fees</a>
                <ul className="sub-menu">
                  <li><Link to="/faq">FAQs</Link></li>
                  <li><Link to="/studentregistartion">Student Registration</Link></li>

                </ul>
                <Link to="/" className="dt-menu-expand fees" onClick={(event) => menuExpandCllick(event, '.fees')}>+</Link>
              </li>
              <li className={location.pathname === "/fascinations" ? "current_page_item lavender" : "lavender"}> <Link to="/fascinations">Our Fascinations</Link> </li>
              <li className={location.pathname === "/franchiseform" ? "current_page_item yellow" : "yellow"}> <Link to="/franchiseform">Franchise Opportunity</Link> </li>
              <li className={location.pathname === "/contact" ? "current_page_item pink" : "pink"}> <Link to="/contact">Contact Us</Link> </li>
            </ul>
          </nav>


          <ul className="dt-sc-social-icons">
            <li>
              <a href="https://www.facebook.com/RangAngelsInternationalPreSchool/" title="Facebook" target="_blank" rel="noreferrer" className="dt-sc-tooltip-top facebook">
                <FontAwesomeIcon icon={faFacebookF} />
                <span className="fa fa-facebook">
                  {/* <FontAwesomeIcon icon={faFacebookF} /> */}
                </span>
              </a>
            </li>
            <li>
              <a href="https://www.youtube.com/channel/UCmOhMw44xj-wLTGNCdovIyA" title="Youtube" target="_blank" rel="noreferrer" className="dt-sc-tooltip-top youtube">
                <FontAwesomeIcon icon={faYoutube} />
                <span className="fa fa-youtube">
                  {/* <FontAwesomeIcon icon={faYoutube} /> */}
                </span>
              </a>
            </li>
            <li>
              <a href="https://www.instagram.com/rangangelsvadodara/" title="Instagram" target="_blank" rel="noreferrer" className="dt-sc-tooltip-top instagram">
                <FontAwesomeIcon icon={faInstagram} />
                <span className="fa fa-instagram">
                  {/* <FontAwesomeIcon icon={faInstagram} /> */}
                </span>
              </a>
            </li>
            {/* <li>
              <a href="/" title="Google Plus" className="dt-sc-tooltip-top gplus">
                <FontAwesomeIcon icon={faGooglePlusG} />
                <span className="fa fa-google-plus">
                  <FontAwesomeIcon icon={faGooglePlusG} />
                </span>
              </a>
            </li> */}
          </ul>
        </div>
      </div>

    </header>
  )
}

export default Header;
const fascinationData = {
    "fascinations": [
        {
            "id": 1,
            "class1": "box1",
            "title": "School Calender",
            "content": "We have designed our academic almanac with fun-loving activities according with seasons, festivals and real life themes. For kid every single day becomes a new reason to cherish and easy learning takeaway.",
            "imgPath": "/assets/media/media1_4gw08D4.jpg",
            "order": 1
        },
        {
            "id": 2,
            "class1": "box2",
            "title": "Festivals",
            "content": "We celebrate and enjoy every single festival by organizing traditional wear or dress competitions. Kids love to participate in multi-cultural activities and end-up learning different traditions & religions of our Nation.",
            "imgPath": "/assets/media/media20170814164910_giqyXtp.jpg",
            "order": 2
        },
        {
            "id": 3,
            "class1": "box2",
            "title": "Activities",
            "content": "Many brain twisting activities along with creative challenges are organized for developing kids at Rang Angels. Activities are well-designed to develop their eye & hand coordination, body movements and life-long learning skills.",
            "imgPath": "/assets/media/mediaIMG201711281246001.jpg",
            "order": 3
        },
        {
            "id": 4,
            "class1": "box3",
            "title": "Tour",
            "content": "Kids love to hangout outside and are always curious to observe the societal happenings by understanding in their own way. We have one of the best thematic planned field trips which are led by our Field Trip Explainers and educators who support student’s learning at the Exploratorium.",
            "imgPath": "/assets/media/mediaIMG-20171129-WA0032_SYAgjgH.jpg",
            "order": 4
        },
        {
            "id": 5,
            "class1": "box1",
            "title": "Herbal Shots",
            "content": "Here at Rang Angels we have started an initiative to make strong immunity of our Rangtoons as every next child is been found suffering from low immunity system nowadays. Kids enjoy drinking natural, tender and colorful herbal shots everyday which acts as best appetizer too.",
            "imgPath": "/assets/media/mediaIMG-20171213-WA0042_idfbHDb.jpg",
            "order": 5
        },
        {
            "id": 6,
            "class1": "box2",
            "title": "Dental & Health Check-up",
            "content": "At Rang Angels we have an excellent team of one of the best Doctors and Dentists of the city to provide health and oral awareness. We arrange health seminarsfor parents and conduct counselling sessions with our Fitness gurus",
            "imgPath": "/assets/media/media20170719204119_5XBowNZ.jpg",
            "order": 6
        },
        {
            "id": 7,
            "class1": "box3",
            "title": "Affirmations & Aura Maker",
            "content": "We have introduced a unique methodology to make child confident and competent since his or her early age to face tomorrow’s challenging world. Our core intention to come up with this program is to make child sure-footed and future ready for this 21st Century.",
            "imgPath": "/assets/media/mediaIMG-20171213-WA0041_weiiZvQ.jpg",
            "order": 7
        },
        {
            "id": 8,
            "class1": "box4",
            "title": "Summer Camp",
            "content": "We have designed our academic almanac with fun-loving activities according with seasons, festivals and real life themes. For kid every single day becomes a new reason to cherish and easy learning takeaway.",
            "imgPath": "/assets/media/mediaIMG-20170515-WA00591.jpg",
            "order": 8
        },
        {
            "id": 10,
            "class1": "box4",
            "title": "Food",
            "content": "Our gourmets and dieticians have designed balanced diet food menu which is full of nutritious values,colorful veggies and fruits. Our beautifully garnished and mouth tempting brunch is loved and enjoyed by every Rangtoon. Many Parents have applauded our efforts as it has changed many kids’ habit of saying No to food at home.",
            "imgPath": "/assets/media/media20170721181035_x4DApUU.jpg",
            "order": 9
        }
    ]
}

export default fascinationData

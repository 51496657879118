import React, { useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBullseye, faEye } from '@fortawesome/free-solid-svg-icons';
import { useEffect, useState } from 'react';
import Slider from "react-slick";
import Hexagone from './shared/Hexagone';
import mask from '../../assets/images/mask.png';
import anurag from "../../assets/images/anurag.jpg";
import priyanka from "../../assets/images/priyanka.jpg"
import "../../assets/css/about.css"
import aboutData from './data/Aboutdata';

function About() {

  const [meetOurTeam, setmeetOurTeam] = useState([]);
  const [parentsreview, setparentsreview] = useState([]);

  useEffect(() => {
    setmeetOurTeam(aboutData['meetOurTeam']);
        setparentsreview(aboutData['parentsreview']);
    // eslint-disable-next-line
  }, []);

 

  let slider = useRef(null);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false
  };

  const next = () => {
    slider.slickNext();
  }

  const previous = () => {
    slider.slickPrev();
  }

  return (
    <>
      <section className="fullwidth_banner">
        <div className="container">
          <h1>About us</h1>
        </div>
      </section>
      <div className="container">

        <div className="dt-sc-hr"></div>
        <div className="dt-sc-hr"></div>
        <div className="column dt-sc-one-half first">
          <div className="dt-sc-ico-content type3">
            <div className="icon">
              {/* <span className="fa fa-eye"> </span> */}
              <FontAwesomeIcon icon={faEye} className="aboutVisionMisson" />
            </div>
            {/* eslint-disable-next-line */}
            <h4><a role="button" tabIndex="0" style={{ color: "#008c99" }}> VISION </a></h4>
            <p> We the Rang Angelians born to spread the quality of education with the innovative way in the
              entire world. Our vision is to identify the forte and domain of the kids and mould them in their
              right form for what he/she has born in this world. </p>
          </div>
        </div>
        <div className="column dt-sc-one-half">
          <div className="dt-sc-ico-content type3">
            <div className="icon">
              {/* <span className="fa fa-bullseye"> </span> */}
              <FontAwesomeIcon icon={faBullseye} className="aboutVisionMisson" />
            </div>
            {/* eslint-disable-next-line */}
            <h4><a role="button" tabIndex="0" style={{ color: "#008c99" }}> MISSION </a></h4>
            <p>Bringing up and educating the kids in such a natural and fearless environment so that they can
              bloom itself like a flower and twinkling with their beauties all over the world. </p>
          </div>
        </div>
        <div className="dt-sc-hr"></div>
      </div>

      <section className="fullwidth-background dt-sc-parallax-section turquoise-bg">
        <div className="container">
          <div className="dt-sc-one-half column first">
            <h2>About Us</h2>
            <div className="dt-sc-ico-content type2">

              <p style={{ textAlign: "justify" }}>Rang Angels is the first unique concept where Love, Care, Affection,
                Positivity, Health, Motivational Affirmations , Life skills have been teaching equally.</p>
              <p style={{ textAlign: "justify" }}>Abundant love and Personal attention are received by every angels here.The
                journey of Rang Angels have been started from 6th April , 2017 with its first step.</p>
              <p style={{ textAlign: "justify" }}>The schooling age starts when the children reach up to 1.5 years. At this
                age they are considered as Rangtoons. They are very curious and have lots of questions in their minds.
                Rang Angels is the place where we have lots of innovative ideas to satisfy their minds.</p>
              <p style={{ textAlign: "justify" }}> It’s a fun filled and activity loaded school that encourage the children
                to understand and develop the basic skills, social kills, languages, curricular activities, singing,
                music, imaginary and many more. We strongly believe that every child is born with the unique personality
                but to recognize it , is the unique quality we have kept. </p>
              <p style={{ textAlign: "justify" }}>Let the child be a person of eminence in this world on his own ability
                with which he/she has born to perform his/her character. We are only the medium of god to show them the
                way to achieve.
              </p>
            </div>
          </div>
          <div className="dt-sc-one-half column">

            <div className="add-slider-wrapper">
              <ul className="add-slider">
                <li>
                  <iframe width="415" height="305" src="https://www.youtube.com/embed/3nHH1SvmEZw" frameBorder="0"
                    allowFullScreen title='Rang Angels'></iframe>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      <div className="container">
        <div className="dt-sc-hr"></div>
        <h2 className="dt-sc-hr-green-title">Let's Meet Our Team</h2>
        {
          meetOurTeam && meetOurTeam.length > 0 && meetOurTeam.map((item, index) => (
            <div className={"column dt-sc-one-third " + (index === 0 ? "first" : "")} key={index}>
              <Hexagone image={item.imgPath} name={item.name} mainoccupation={item.occupation} suboccupation={item.information} mask={mask} />
            </div>
          ))
        }

        


        <div className="dt-sc-hr"></div>
        <h2 className="dt-sc-hr-green-title">Let's Meet Our Founder and Directors</h2>
        <div className="column dt-sc-one-half first">
          <div className="dt-sc-team">
            <div className="image">
              <img className="item-mask" src={mask} alt="" title="" />
              <img src={anurag} alt="" title="" />
              <div className="dt-sc-image-overlay">
                {/* eslint-disable-next-line */}
                <a role="button" tabIndex="0" className="link"><span className="fa fa-facebook" style={{ color: "#008c99" }} ></span></a>
                {/* eslint-disable-next-line */}
                <a role="button" tabIndex="0" className="zoom"><span className="fa fa-twitter" style={{ color: "#008c99" }} ></span></a>
              </div>
            </div>
            <div className="team-details">
              <h4> Mr. Anuraag H. Dixit </h4>
              <h6> Director (Rang Angels), Chairman (Shree Ambe Public School) </h6>
              <blockquote className="aligncenter green"><q>
                <p> It is always believed that people come in our life Have some reasons, and sometimes you don't
                  expect that a special person will have such a huge impact on our life until they just do.<br />we can
                  constantly learn from them ,and strive to be just like them in all the best ways.<br />
                  <br />
                  " CREATIVITY IS THE KEY TO SUCCESS IN THE FUTURE ,AND PRE SCHOOL
                  EDUCATION IS WHERE TEACHERS CAN BRING CREATIVITY IN CHILDREN
                  AT THAT LEVEL… "<br />
                  <br />
                  A very talented & dynamic Mr.Anuraag is a Role model of number of students... He is the personality
                  maker, friend philosopher and best guide of his students who are performing their best roles in the
                  society.<br />Where number of students are Doctors , Engineers, Chartered Accountants, and so on….
                  Since 2004, Mr.Anuraag has been successfully running his coaching classes and in 2014,<br />He became
                  the Chairman and Owner of Shree Ambe Public School at his home town with a view to provide the best
                  education with his great mission and vision.
                </p>
              </q></blockquote>
            </div>
          </div>
        </div>
        <div className="column dt-sc-one-half">
          <div className="dt-sc-team">
            <div className="image">
              <img className="item-mask" src={mask} alt="" title="" />
              <img src={priyanka} alt="" title="" />
              <div className="dt-sc-image-overlay">
                {/* eslint-disable-next-line */}
                <a role="button" tabIndex="0" className="link"><span className="fa fa-facebook" style={{ color: "#008c99" }}></span></a>
                {/* eslint-disable-next-line */}
                <a role="button" tabIndex="0" className="zoom"><span className="fa fa-twitter" style={{ color: "#008c99" }}></span></a>
              </div>
            </div>
            <div className="team-details">
              <h4> Mrs. Priyanka A. Dixit </h4>
              <h6> Founder & C.E.O (Rang Angels) </h6>
              <blockquote className="aligncenter green"><q>
                <p>Mrs Priyanka is a person of skills. Having ten years of experience in the educational field, worked
                  as a lecturer & principal in different institutes. Working on child psychology &
                  Writer of two books and the best team leader,she strongly believes to change the era of pre school
                  through her unique concepts.According to her we can cultivate so many qualities in the child. Every
                  child is very special for their parents,society,school as well as Nation.It is so important to have
                  quality in education.At RANG ANGELS,children are getting the best curriculum,self
                  motivation,positive AURA ,confidence and homely atmosphere.
                  <br /> <br /> She says "it was my dream to cover up all the needs of a child at the same place so that I
                  can reach up to my goal that is self satisfaction!! <br /> <br /> I am enjoying every day to see each
                  and every things live from my eyes...which I hv dreamt..It gives me satisfaction when the parents
                  tell me that the kids love to come in school even in weekends too....

                </p>
              </q></blockquote>
            </div>
          </div>
        </div>

        <blockquote className="aligncenter hello">
          <q>HELLO every one !!!<br />
            RANG ANGELS …is a jointly visualized dream from the imaginary world of MR. ANURAAG DIXIT & MRS PRIYANKA
            ANURAAG DIXIT.
            Rang Angels International school is a dream by open eyes …its dream of different planets which is specially
            designed for angels. we have spend number of days and nights to think about ..
            ‘EK SCHOOL ESA HO TO …ISME YE KARE TO…ESE KARE TO …
            ‘why not parents? why not Grand parents? Why don’t we form a family ?<br />
            We are very proud to say that yes we have really the dream pre-school of every parents ….
            Where the children can get love ,care ,safety ,health ,strong immunity ,best education ,creativity,
            practical training as well as ‘POWERFULLL SELF AFFIRMATIONS’

            WE ARE really lucky that We could get support from each and every parents who joined us in “RANG ANGELS
            PARIWAAR” .
            We would like to invite people around the world to join our PARIVAAR to MAKE the HISTORY …
            <br />
            With love ,<br />
            Priyanka & Anuraag
          </q>
        </blockquote>
        <div className="dt-sc-hr"></div>
        <div className="dt-sc ">
          <h2>Some Words from Parents and our Former Students</h2>
          <div className="dt-sc-testimonial-carousel-wrapper">
            <Slider ref={c => (slider = c)} {...settings}>

              {
                parentsreview && parentsreview.length > 0 && parentsreview.map((item, index) => (
                  <div className={'dt-sc-testimonial ' + (index === 0 ? "dt-sc-testimonial-carousel" : "")} key={index}>
                    <blockquote style={{ width: "100%" }}><q>{item.message}</q></blockquote>
                    <div className='author'>
                      <img src="/assets/media/def-img.webp" alt="" title="" />
                    </div>
                    <div className="author-meta">
                      <p>{item.name}</p>
                      <span>{item.occupation}</span>
                      <span className="author-rating rating-5"></span>
                    </div>
                  </div>
                ))
              }

            </Slider>
            <div className="carousel-arrows">
              <div className="testimonial-prev" onClick={() => previous()} style={{ cursor: "pointer" }}><span className="fa fa-angle-left" style={{ paddingRight: '1px', paddingBottom: '1px' }} ></span></div>
              <div className="testimonial-next" onClick={() => next()} style={{ cursor: "pointer" }}><span className="fa fa-angle-right" style={{ paddingRight: '1px', paddingBottom: '1px' }} ></span></div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default About;
import { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLink } from '@fortawesome/free-solid-svg-icons';
import Hexagone from './shared/Hexagone';
import { Link } from 'react-router-dom';
import mask from '../../assets/images/mask.png';
import Slider from "react-animated-slider";
import "react-animated-slider/build/horizontal.css";
import "normalize.css/normalize.css";
import "../..//assets/css/slider-animations.css";
import staticData from './data/Homedata';
function Home() {
  const [ourCurriculum, setOurCurriculum] = useState([]);
  const [carousel, setCarousel] = useState([]);
  const [meetOurTeam, setMeetOurTeam] = useState([]);
  const [ourfascination, setOurFascination] = useState([]);
  const [services, setServices] = useState([]);
  const [recentActivities, setRecentActivities] = useState([]);

  useEffect(() => {

    setOurCurriculum({ result: splitIntoChunk(staticData.ourcurriculum, 3) });
    setCarousel(staticData.carouselimg);
    setMeetOurTeam(staticData.meetOurTeam);
    setOurFascination(staticData.ourfascination);
    setServices(staticData.services);
    setRecentActivities(staticData.recentActivites);
  }, []);

  const splitIntoChunk = (arr, chunk) => {
    let finalData = [];
    for (let i = 0; i < arr.length; i += chunk) {
      let tempArray = arr.slice(i, i + chunk);
      finalData.push({ 'data': tempArray });
    }
    return finalData;
  };

  return (
    <>
      <div id='slider'>
        <div id="layerslider_4" className="ls-wp-container" style={{ "width": "100%", "height": "610px", maxWidth: "1920px", "margin": "0 auto", "marginBottom": "0px" }}>
          <Slider className="slider-wrapper" autoplay={4000}>
            {carousel.map((item, index) => (
              <div
                key={index}
                className="slider-content"
                style={{ background: `url('${item.imgPath}') ${item.isCenter !== 'No' ? 'center ' : ''}no-repeat` }}
              >
              </div>
            ))}
          </Slider>
        </div>
      </div>

      <section id="primary" className="content-full-width">

        <div className="container">
          {
            services && services.length > 0 && services.map((item, index) => (
              <div className={"dt-sc-one-fourth column " + (index === 0 ? "first" : "")} key={index}>
                <div className="dt-sc-ico-content type1">
                  <div className="icon">
                    <span className="icon-outer">
                      <img src={item.imgPath} alt="" title="" />
                      <span className="infolayer">
                        <Link to={item.link}><FontAwesomeIcon icon={faLink} style={{ color: "#008c99" }} /></Link>
                      </span>
                    </span>
                  </div>
                  <h4 className={item.classname}><Link to={item.link}>{item.title}</Link></h4>
                </div>
              </div>
            ))
          }



        </div>

        <div className="dt-sc-hr"></div>

        <section className="fullwidth-background dt-sc-parallax-section turquoise-bg">

          {
            ourCurriculum && (
              <div className="container">
                <div className="">
                  <h2 style={{ marginBottom: "1.5rem" }}>Our Curriculum</h2>
                  {
                    ourCurriculum.result && ourCurriculum.result.length > 0 &&
                    ourCurriculum.result.map((item, index) => (
                      <div className={"dt-sc-one-third column " + (index === 0 ? "first" : "")} key={index}>
                        {
                          item.data && item.data.length > 0 &&
                          item.data.map((itm, idx) => (
                            <div key={idx}>
                              <div className="dt-sc-ico-content type2">
                                <div className="icon">
                                  <span>
                                    {/* <FontAwesomeIcon icon={itm.icon} /> */}
                                    <i className={itm.icon} aria-hidden="true"></i>
                                  </span>
                                </div>
                                <h4><Link to="/curriculum" target="_blank"> {itm.title} </Link></h4>
                                <p>{itm.description}</p>
                              </div>
                              <div className="dt-sc-hr-very-small"></div>
                            </div>
                          ))
                        }
                      </div>
                    ))
                  }
                </div>
              </div>
            )
          }
        </section>
        <div className="dt-sc-hr"></div>
        
        <div className="container">
          <h2 className="dt-sc-hr-green-title">Our Fascinations</h2>
          <div className="front-portfolio-container">


            {
              ourfascination && ourfascination.length > 0 && ourfascination.map((item, index) => (
                <div className={"portfolio-content " + item.class} key={index}>
                  <div className="front-portfolio">
                    <div className="portfolio-outer">
                      <div className="portfolio-thumb">
                        <img src={item.imgPath} alt="" title="" />
                        <div className="image-overlay">
                          <h5><Link to="/scheduler">{item.title}</Link></h5>
                          <Link to="/fascinations" className="link"><span className="fa fa-link"></span></Link>
                          <a href={item.imgPath} data-gal="prettyPhoto[gallery]" className="zoom" target="_blank" rel="noreferrer"><span className="fa fa-search"></span></a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            }

            <div className="dt-sc-hr-small"></div>
            <div className="aligncenter">
              <Link to="/scheduler" className="dt-sc-button medium mustard">  School Calender   <span className="fa fa-chevron-circle-right"> </span></Link>
            </div>
          </div>
        </div>
        <div className="dt-sc-hr"></div>
        <div className="dt-sc-hr"></div>
        <h2 className="dt-sc-hr-green-title">Let's Meet Our Team</h2>

        {
          meetOurTeam && meetOurTeam.length > 0 && meetOurTeam.map((item, index) => (
            <div className={"column dt-sc-one-third " + (index === 0 ? "first" : "")} key={index}>
              <Hexagone image={item.imgPath} name={item.name} mainoccupation={item.ocuupation} suboccupation={item.information} mask={mask} />
            </div>
          ))
        }

        <div className="dt-sc-hr"></div>

        <section className="fullwidth-background dt-sc-parallax-section product_bg">

          <div className="container">
            <h2 className="dt-sc-hr-white-title">Recent Activities</h2>
            <ul className="products">
              {
                recentActivities && recentActivities.length > 0 && recentActivities.map((item, index) => (
                  <li className={"dt-sc-one-fourth column " + (index === 0 ? "first" : "")} key={index}>
                    <div className="product-thumb">
                      <Link to="/">
                        <img src={item.imgPath} alt="" title="" />
                      </Link>

                      <div className="image-overlay">
                        <div className="product-button">
                          <Link to="/" className="add-cart-btn">{item.link}</Link>
                        </div>
                      </div>
                    </div>
                    <div className="product-details">
                      <h5><Link to="/">{item.title}</Link></h5>
                    </div>
                  </li>
                ))
              }
            </ul>
          </div>

        </section>

      </section>
    </>
  )
}
export default Home;
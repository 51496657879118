import { useState } from "react";
import TextField from "@mui/material/TextField";
import { useHistory } from "react-router-dom";

function Newsletter() {
  const [email, setEmail] = useState(null);
  const history = useHistory();

  const onSubmit = () => {
    const finalData = new FormData();
    finalData.append("email", email);

    const requestOptions = {
      method: "POST",
      body: finalData,
    };
    console.log("requestOptions: ", requestOptions);

    fetch("http://localhost:8000/tmpadmin/addnewsletter", requestOptions)
      .then((response) => response.json())
      .then((data) => {
        console.log("data: ", data);
        if (data.status) {
          history.push("/");
        }
      })
      .catch((error) => {
        console.log("error: ", error);
        // onSaveBtnClick();
      });
  };

  return (
    <>
      <div className="news_container">
        <div className="news_box">
          <h1>Subscribe to our Newsletter</h1>
          <p>Lots of crucial information are going to come on the way!!!</p>
          <div className="news_subscription">
            <TextField
              id="outlined-basic"
              label="Email Address"
              variant="outlined"
              className="txtField"
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            />
            <button className="news_button" onClick={() => onSubmit()}>
              Subscribe
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default Newsletter;

const staticData = {
    "ourcurriculum": [
        {
            "id": 2,
            "icon": "fa fa-pencil",
            "title": "Language Concepts",
            "description": "Language concepts are developed through activities that include listening, dramatic play, recalling story events following verbal directions....",
            "order": 1
        },
        {
            "id": 3,
            "icon": "fa fa-futbol-o",
            "title": "Physical development",
            "description": "At Rang Angels we have arranged Physical Development activities in our planner for kids that makes them super active.....",
            "order": 2
        },
        {
            "id": 4,
            "icon": "fa fa-paint-brush",
            "title": "Creative activities",
            "description": "\"We foster kids’ brain and try to make them outspoken by encouraging them to participate in many activities to showcase their talent by using their creativity...",
            "order": 3
        },
        {
            "id": 5,
            "icon": "fa fa-bullseye",
            "title": "Life skill",
            "description": "Life Skills is a diverse area, where children are taught the importance of using positive manner in both words & attitude....",
            "order": 4
        },
        {
            "id": 6,
            "icon": "fa fa-cubes",
            "title": "Fine motor skills",
            "description": "We follow scientifically designed activities to do develop Fine Motor Skills to write and manipulate small objects.....",
            "order": 5
        },
        {
            "id": 7,
            "icon": "fa fa-lightbulb-o",
            "title": "Mathematical concepts",
            "description": "Mathematical concepts are made understood through activities that include matching objects, labeling numerals....",
            "order": 6
        },
        {
            "id": 8,
            "icon": "fa fa-music",
            "title": "Music skill",
            "description": "Music Skills help children to acquire language, sense of rhythm and develop Gross Motor Skill with their body movements....",
            "order": 7
        },
        {
            "id": 9,
            "icon": "fa fa-chevron-circle-right",
            "title": "Art and Craft",
            "description": "We have designed our interdisciplinary curriculum with various art & craft activities which help them understand nature of art...",
            "order": 8
        },
        {
            "id": 10,
            "icon": "fa fa-thumbs-up",
            "title": "Sensorial activities",
            "description": "We work on Sensorial Activities which stimulates child’s senseslike smell,taste,movement,balance,sight and hearing......",
            "order": 9
        }
    ],
    "meetOurTeam": [
        
        {
            "id": 1,
            "name": "Dr. Meena Dodeja Barot",
            "ocuupation": "Ph.D. in Dance",
            "information": "Shree kala kendra",
            "imgPath": "/assets/media/mediadrmina.png",
            "order": 1
        },
        {
            "id": 2,
            "name": "Dr. Minaxi Patel",
            "ocuupation": "Dietician",
            "information": "Ayurveda Doctor",
            "imgPath": "/assets/media/mediadrminaxi.png",
            "order": 2
        }
    ],
    "services": [
        {
            "id": 1,
            "title": "Staff Recruitment",
            "classname": "download colorpink",
            "imgPath": "/assets//media/mediaservice1_PAmAEid.jpg",
            "link": "/staffrecruitment",
            "order": 1
        },
        {
            "id": 2,
            "title": "Scheduler",
            "classname": "download colormustard",
            "imgPath": "/assets//media/mediaservice2.jpg",
            "link": "/schedular",
            "order": 2
        },
        {
            "id": 3,
            "title": "Picture Gallery",
            "classname": "download coloryellow",
            "imgPath": "/assets//media/mediaservice3.jpg",
            "link": "curriculum",
            "order": 3

        },
        {
            "id": 4,
            "title": "Activities",
            "classname": "download colorgreen",
            "imgPath": "/assets//media/mediaservice4.jpg",
            "link": "/fascinations",
            "order": 4
        }
    ],
    "ourfascination": [
        {
            "id": 1,
            "title": "Summer Camp",
            "class": "portfolio-content1",
            "imgPath": "/assets//media/mediasummer-camp.jpg",
            "order": 1
        },
        {
            "id": 2,
            "title": "Affirmation & Aura Maker",
            "class": "portfolio-content2",
            "imgPath": "/assets//media/mediaIMG-20171213-WA0041.jpg",
            "order": 2
        },
        {
            "id": 3,
            "title": "Herbal Shots",
            "class": "portfolio-content3",
            "imgPath": "/assets//media/mediaIMG-20171213-WA0042.jpg",
            "order": 3
        },
        {
            "id": 4,
            "title": "dental & Health Check-up",
            "class": "portfolio-content4",
            "imgPath": "/assets//media/media20170719204119.jpg",
            "order": 4
        },
        {
            "id": 5,
            "title": "Food",
            "class": "portfolio-content5",
            "imgPath": "/assets//media/media20170721181035.jpg",
            "order": 5
        },
        {
            "id": 6,
            "title": "Tour",
            "class": "portfolio-content6",
            "imgPath": "/assets//media/mediaIMG-20171129-WA0032.jpg",
            "order": 6
        },
        {
            "id": 7,
            "title": "Festivals",
            "class": "portfolio-content7",
            "imgPath": "/assets//media/media20170814164910.jpg",
            "order": 7
        }
    ],
    "recentActivites": [
        {
            "id": 2,
            "title": "Activity1",
            "link": "More",
            "imgPath": "/assets//media/media03_home.jpg",
            "order": 1
        },
        {
            "id": 3,
            "title": "Activity 2",
            "link": "More",
            "imgPath": "/assets//media/mediaIMG20171114131222.jpg",
            "order": 2
        },
        {
            "id": 4,
            "title": "Activity 3   ",
            "link": "More",
            "imgPath": "/assets//media/mediaIMG20170912124502.jpg",
            "order": 3
        },
        {
            "id": 5,
            "title": "Activity1",
            "link": "More",
            "imgPath": "/assets//media/mediaIMG20171128114914.jpg",
            "order": 4
        }
    ],
    "carouselimg": [
        {
            "id": 4,
            "imgPath": "/assets//media/mediaslider-1-t1EWqT914-transformed_dL6KWAQ.webp",
            "order": 1,
            "isCenter": "Yes"
        },
        {
            "id": 2,
            "imgPath": "/assets//media/mediaslider-3-transformed_zY7ZFWv.webp",
            "order": 2,
            "isCenter": "Yes"
        },
        {
            "id": 3,
            "imgPath": "/assets//media/mediaslider-2-transformed_nmzKyn8.webp",
            "order": 3,
            "isCenter": "Yes"
        },
        
    ]
};

  export default staticData;
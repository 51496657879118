import { useEffect, useState } from "react";
import curriculumdata from "./data/Curriculumdata";
function Curriculum() {
  const [curriculum, setcurriculum] = useState([]);

  useEffect(() => {
    splitIntoChunk(curriculumdata["curriculum"], 4);
    // eslint-disable-next-line
  }, []);

 

  const splitIntoChunk = (arr, chunk) => {
    let obj = {};
    let finalData = [];
    for (let i = 0; i < arr.length; i += chunk) {
      let tempArray;
      tempArray = arr.slice(i, i + chunk);
      obj = {
        row: tempArray,
      };
      finalData.push(obj);
    }
    setcurriculum(finalData);
  };

  return (
    <>
      <section className="fullwidth_banner">
        <div className="container">
          <h1>Our Curriculum</h1>
        </div>
      </section>

      <div className="container">
        <section
          id="primary"
          className="content-full-width"
          style={{ marginBottom: 80 }}
        >
          <h4 className="dt-sc-hr-green-title" style={{ color: "#4cbcd4" }}>
            The curriculum and activities at our RANG ANGELS’ Kids have been
            designed to develop inquiring minds and healthy bodies.
          </h4>
          {curriculum &&
            curriculum.length > 0 &&
            curriculum.map((item, index) => (
              <div key={index}>
                <div className="container">
                  {item.row.map((itm, idx) => (
                    <div key={index + idx}>
                      <div className={"column dt-sc-one-half " + itm.class1}>
                        <div className={"activity " + itm.class2}>
                          <h4>{itm.title}</h4>
                          <img
                            src={itm.imgPath}
                            alt=""
                            title=""
                            style={{ height: "424px" }}
                          />
                          <p style={{ textAlign: "justify" }}>{itm.content}</p>
                        </div>
                      </div>
                      {idx % 2 !== 0 ? <div className="dt-sc-hr"></div> : null}
                    </div>
                  ))}
                </div>
                <div className="dt-sc-hr"></div>
              </div>
            ))}
        </section>
      </div>
    </>
  );
}

export default Curriculum;

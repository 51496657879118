import { useState } from "react";
import TextField from "@mui/material/TextField";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import { useHistory } from "react-router-dom";

function Contact() {
  const [firstName, setfirstName] = useState(null);
  const [lastName, setLastName] = useState(null);
  const [email, setEmail] = useState(null);
  const [message, setMessage] = useState(null);

  const history = useHistory();

  const onSubmit = () => {
    const finalData = new FormData();
    finalData.append("firstname", firstName);
    finalData.append("lastname", lastName);
    finalData.append("email", email);
    finalData.append("message", message);

    const requestOptions = {
      method: "POST",
      body: finalData,
    };
    console.log("requestOptions: ", requestOptions);

    fetch("http://localhost:8000/tmpadmin/addcontactinfo", requestOptions)
      .then((response) => response.json())
      .then((data) => {
        console.log("data: ", data);
        if (data.status) {
          history.push("/");
        }
      })
      .catch((error) => {
        console.log("error: ", error);
        // onSaveBtnClick();
      });
  };

  return (
    <>
      <div className="contact">
        <section className="contact__section">
          <div className="first__container">
            <div className="contact__info">
              <div>
                <h2 className="contact__title">Contact Info</h2>
                <ul className="info">
                  <li>
                    <span>
                      <i className="ri-map-pin-range-line contact__icon"></i>
                    </span>
                    <span>
                      69, Sai Sarjan Bunglows, Opp. darshanam Elite, Sun Pharma
                      Road<br></br> Atladra, 390012 Vadodara.
                    </span>
                  </li>
                  <li>
                    <span>
                      <i className="ri-mail-add-line contact__icon"></i>
                    </span>
                    <a
                      href="mailto:info@rangangels.com"
                      style={{ color: "white" }}
                    >
                      info@rangangels.com
                    </a>
                  </li>
                  <li>
                    <span>
                      <i className="ri-phone-line contact__icon"></i>
                    </span>
                    <div>
                      <a href="tel:+919574318880" style={{ color: "white" }}>
                        +91 9574318880
                      </a>
                      <br />
                      <a href="tel:+917573033362" style={{ color: "white" }}>
                        +91 7573033362
                      </a>
                    </div>
                  </li>
                  <li className="contact__social">
                    <a
                      href="https://www.facebook.com/RangAngelsInternationalPreSchool/"
                      target="_blank"
                      rel="noreferrer"
                      className="home__social-link"
                    >
                      <i className="ri-facebook-box-fill"></i>
                    </a>
                    <a
                      href="https://www.instagram.com/rangangelsvadodara/"
                      target="_blank"
                      rel="noreferrer"
                      className="home__social-link"
                    >
                      <i className="ri-instagram-fill"></i>
                    </a>
                    <a
                      href="https://www.youtube.com/channel/UCmOhMw44xj-wLTGNCdovIyA"
                      target="_blank"
                      rel="noreferrer"
                      className="home__social-link"
                    >
                      <i className="ri-youtube-fill"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="contact__form">
              <h2 className="form__title">Send a Message</h2>
              <div className="form__box">
                <div className="d-flex  w-100 gap-4">
                  <div className="input__box" style={{ width: "50%" }}>
                    <TextField
                      id="outlined-basic"
                      label="First Name"
                      variant="outlined"
                      className="txtField_contact w-100"
                      onChange={(e) => {
                        setfirstName(e.target.value);
                      }}
                    />
                  </div>
                  <div className="input__box" style={{ width: "50%" }}>
                    <TextField
                      id="outlined-basic"
                      label="Last Name"
                      variant="outlined"
                      className="txtField_contact w-100"
                      onChange={(e) => {
                        setLastName(e.target.value);
                      }}
                    />
                  </div>
                </div>
                <div className="input__box w-100">
                  <TextField
                    id="outlined-basic"
                    label="Email Address"
                    variant="outlined"
                    className="txtField_contact w-100"
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                  />
                </div>
                <div className="input__box w-100">
                  <TextareaAutosize
                    aria-label="minimum height"
                    minRows={3}
                    placeholder="Enter Comments"
                    style={{ border: "1.2px solid rgb(202, 194, 194)" }}
                    className="textborder w-100"
                    onChange={(e) => {
                      setMessage(e.target.value);
                    }}
                  />
                </div>
                <div className="input__box w100">
                  <input
                    className="form__input"
                    type="submit"
                    value="Send"
                    onClick={() => onSubmit()}
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default Contact;

const schedularData = {
    "scheduler": [
        {
            "id": 13,
            "imgPath": "/assets/media/schedular/media-1.webp",
            "order": 1
        },
        {
            "id": 14,
            "imgPath": "/assets/media/schedular/media-2.webp",
            "order": 2
        },
        {
            "id": 15,
            "imgPath": "/assets/media/schedular/media-3.webp",
            "order": 3
        },
        {
            "id": 16,
            "imgPath": "/assets/media/schedular/media-4.webp",
            "order": 4
        },
        {
            "id": 17,
            "imgPath": "/assets/media/schedular/media-5.webp",
            "order": 5
        },
        {
            "id": 18,
            "imgPath": "/assets/media/schedular/media-6.webp",
            "order": 6
        },
        {
            "id": 19,
            "imgPath": "/assets/media/schedular/media-7.webp",
            "order": 7
        },
        {
            "id": 20,
            "imgPath": "/assets/media/schedular/media-8.webp",
            "order": 8
        },
        {
            "id": 21,
            "imgPath": "/assets/media/schedular/media-9.webp",
            "order": 9
        },
        {
            "id": 22,
            "imgPath": "/assets/media/schedular/media-10.webp",
            "order": 10
        },
        {
            "id": 23,
            "imgPath": "/assets/media/schedular/media-10.webp",
            "order": 11
        }
    ]
}

export default schedularData
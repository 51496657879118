// eslint-disable-next-line
import React, { useEffect } from 'react';
import { Switch, Route } from 'react-router-dom';
import Home from './components/views/Home';
import About from './components/views/About';
import Curriculum from './components/views/Curriculum';
import Scheduler from './components/views/Scheduler';
import TeacherTraning from './components/views/TeacherTraning';
import Faq from './components/views/Faq';
import Fascinations from './components/views/Fascinations';
import Franchiseform from './components/views/Franchiseform';
import Contact from './components/views/Contact';
import Studentregistartion from './components/views/Studentregistration';
import Staffrecruitment from './components/views/Staffrecruitment';
import Newsletter from './components/views/Newsletter';

const Router = () => {
  // disable right click
  
  // useEffect(() => {
  //   document.addEventListener('contextmenu', (e) => {
  //     e.preventDefault();
  //   });
  // });

  return (
    <Switch>
      <Route exact path='/' component={Home} />
      <Route exact path='/about' component={About} />
      <Route exact path='/curriculum' component={Curriculum} />
      <Route exact path='/scheduler' component={Scheduler} />
      <Route exact path='/teachertraning' component={TeacherTraning} />
      <Route exact path='/faq' component={Faq} />
      <Route exact path='/fascinations' component={Fascinations} />
      <Route exact path='/franchiseform' component={Franchiseform} />
      <Route exact path='/contact' component={Contact} />
      <Route exact path='/studentregistartion' component={Studentregistartion} />
      <Route exact path='/staffrecruitment' component={Staffrecruitment} />
      <Route exact path='/newsletter' component={Newsletter} />

    </Switch>
  )
}

export default Router;
function Hexagone(props){
  return (
    <>
    <div className="dt-sc-team">
            <div className="image">
              <img className="item-mask" src={props.mask} alt="" title="" />
              <img src={props.image} alt="" title="" />
              <div className="dt-sc-image-overlay">
                {/* eslint-disable-next-line */}
                <a role="button" tabIndex="0" className="link"><span className="fa fa-facebook" style={{ color: "#008c99" }}></span></a>
                {/* eslint-disable-next-line */}
                <a role="button" tabIndex="0" className="zoom"><span className="fa fa-twitter" style={{ color: "#008c99" }}></span></a>
              </div>
            </div>
            <div className="team-details">
              <h4>{props.name}</h4>
              <h6>{props.mainoccupation}<br />
                {props.suboccupation}</h6>

            </div>
          </div>
    </>
  )
}

export default Hexagone;
import { useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChild } from '@fortawesome/free-solid-svg-icons';
import $ from 'jquery';

import Header from "./Header";
import Footer from "./Footer";

// import sgtPost from "../../assets/images/suggest-post.png";
import { Link } from 'react-router-dom';

// eslint-disable-next-line
export default (ComposendComponent) => {
  const WithLayout = (props) => {

    let offset = 220;
    let duration = 500;

    const onBackToTopClick = (event) => {
      event.preventDefault();
      $('html, body').animate({ scrollTop: 0 }, duration);
      return false;
    }

    const handleScroll = () => {
      if ($(window).scrollTop() > offset) {
        $('.back-to-top').fadeIn(duration);
      } else {
        $('.back-to-top').fadeOut(duration);
      }
    }

    // const onSuggestPostClick = () => {
    //   console.log('onSuggestPostClick: ');
    // }

    useEffect(() => {
      window.addEventListener('scroll', handleScroll);
    })

    return (
      <>
        <div className="wrapper">
          <Header />
          <div id="main">
            {/* <div id="suggestPost" style={{ backgroundImage: `url(${sgtPost})` }} onClick={() => onSuggestPostClick()}><a href="/" style={{ display: "none" }}>Hii</a></div> */}
            <ComposendComponent {...props} />
          </div>
          <div id="mySidenav" className="sidenav">
            <Link to="/" id="about"><FontAwesomeIcon icon={faChild} style={{ marginRight: "40px" }} />After School Club</Link>
          </div>
          <Footer />
        </div>
        <Link to="/" title="Go to Top" className="back-to-top" onClick={(event) => onBackToTopClick(event)}>To Top ↑</Link>
      </>
    )
  }
  return WithLayout;
}
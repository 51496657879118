import React, { useRef, useEffect, useState } from "react";
import Slider from "react-slick";
import schedularData from "./data/Schedular";
function Scheduler() {
  const [scheduler, setscheduler] = useState([]);

  useEffect(() => {
    setscheduler(schedularData["scheduler"]);
    // eslint-disable-next-line
  }, []);

  

  let slider = useRef(null);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
  };

  const next = () => {
    slider.slickNext();
  };

  const previous = () => {
    slider.slickPrev();
  };

  return (
    <>
      <section className="fullwidth_banner">
        <div className="container">
          <h1>Scheduler Calendar</h1>
        </div>
      </section>
      <div className="container">
        <div className="dt-sc-hr"></div>
        <div className="dt-sc-hr"></div>

        <div className="dt-sc-one-half " style={{ width: "100%" }}>
          <div
            className="dt-sc-testimonial-carousel-wrapper"
            style={{ marginBottom: 80 }}
          >
            <Slider
              className="dt-sc-testimonial-carousel"
              ref={(c) => (slider = c)}
              {...settings}
            >
              {scheduler &&
                scheduler.length > 0 &&
                scheduler.map((item, index) => (
                  <div className="dt-sc-testimonial" key={index}>
                    <img src={item.imgPath} alt="" title="" />
                  </div>
                ))}
            </Slider>
            <div className="carousel-arrows">
              <div
                className="testimonial-prev"
                onClick={() => previous()}
                style={{ cursor: "pointer" }}
              >
                <span
                  className="fa fa-angle-left"
                  style={{ paddingRight: "1px", paddingBottom: "1px" }}
                ></span>
              </div>
              <div
                className="testimonial-next"
                onClick={() => next()}
                style={{ cursor: "pointer" }}
              >
                <span
                  className="fa fa-angle-right"
                  style={{ paddingRight: "1px", paddingBottom: "1px" }}
                ></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Scheduler;
